import * as React from "react";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {getIn, insert} from "formik";
import PropTypes from "prop-types";
import {PillContainerField} from "../../common/ui/form/pillContainerField";
import Lookup from "../../common/ui/lookup/lookup";

const DeviceTagPillContainerField = (props) => {
    const {orgId} = props;
    const name = "tags";
    const label = "Device Tags";
    const QUERY_TAG_SUGGESTIONS = gql`
        query ($orgId: ID!, $search: String) {
            getTagListForOrganisation(orgId: $orgId, search: $search)
        }
    `;

    const tagSuggestions = useQuery(QUERY_TAG_SUGGESTIONS, {
        variables: {
            orgId: orgId
        }
    });

    return <PillContainerField
        name={name} label={label} pillLabelExtractor={(it) => it}
        //readOnly={readOnly}
        renderLookup={(formik) => {
            const {form} = formik;
            return <Lookup
                placeholder={"Add Device Tag ..."}
                subtitleExtractor={() => undefined}
                titleExtractor={(it) => it}
                onEnterCallback={(e) => {
                    let list = getIn(form.values, name) || [];
                    let value = e.currentTarget.value;
                    form.setFieldValue(name, insert(list, list.length, value));
                }}
                loadSuggestions={keyword => tagSuggestions.refetch({orgId: orgId, search: keyword})
                    .then(result => {
                        let suggestions = result?.data?.getTagListForOrganisation;
                        if (keyword) {
                            if(suggestions == undefined){
                                suggestions = []
                            }
                            suggestions.push(keyword)
                        }
                        suggestions = _.uniq(suggestions)
                        suggestions = suggestions.filter(item => !form.values.tags?.includes(item))
                        return suggestions;
                    })
                }
                onLookup={(value) => {
                let list = getIn(form.values, name) || [];
                form.setFieldValue(name, insert(list, list.length, value));
            }}
            />;
        }
        }
    />
};

DeviceTagPillContainerField.defaultProps = {
    readOnly: false
};

DeviceTagPillContainerField.propTpyes = {
    orgId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool
};



export default DeviceTagPillContainerField;