import React from "react";
import PropTypes from "prop-types";
import requiredIf from 'react-required-if';

import {DataTable} from '@salesforce/design-system-react';
import {DataTableColumn} from '@salesforce/design-system-react';
import {DataTableCell} from '@salesforce/design-system-react';
import {Card} from '@salesforce/design-system-react';
import {CardEmpty} from '@salesforce/design-system-react';
import {Icon as SldsIcon} from '@salesforce/design-system-react';
import {IconSettings} from '@salesforce/design-system-react';
import {PageHeader} from '@salesforce/design-system-react';
import {Button as SldsButton} from '@salesforce/design-system-react';
import {ButtonGroup} from '@salesforce/design-system-react';
import {ButtonStateful} from '@salesforce/design-system-react';
import {Dropdown} from '@salesforce/design-system-react';
import {SplitView} from '@salesforce/design-system-react';
import {SplitViewHeader} from '@salesforce/design-system-react';
import {SplitViewListbox} from '@salesforce/design-system-react';
import {DropdownTrigger} from '@salesforce/design-system-react';
import {ToastContainer as SldsToastContainer} from '@salesforce/design-system-react';
import {Toast as SldsToast} from '@salesforce/design-system-react';
import {Tooltip} from '@salesforce/design-system-react';
import {Modal as SldsModal} from '@salesforce/design-system-react';
import classNames from "classnames"

export function ToastContainer(props) {
    return <SldsToastContainer {...props}/>
}

export function Toast(props) {
    return <SldsToast {...props}/>
}


export function Modal(props) {
    return <SldsModal contentClassName="slds-p-around--x-small" dismissOnClickOutside={false} {...props}>

    </SldsModal>
}

Modal.propTypes = {
    align: PropTypes.oneOf(['top', 'center']),
    ariaHideApp: PropTypes.bool,
    assistiveText: PropTypes.shape({
        dialogLabel: PropTypes.string,
        dialogLabelledBy: PropTypes.string,
        closeButton: PropTypes.string
    }),

    /**
     * Modal content.
     */
    children: PropTypes.node.isRequired,

    /**
     * Custom CSS classes for the modal `section` node classed `.slds-modal` and the parent of `.slds-modal__container`. Uses `classNames` [API](https://github.com/JedWatson/classnames).
     */
    className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * Custom CSS classes for the modal's container. This is the child element of `.slds-modal` with class `.slds-modal__container`. Uses `classNames` [API](https://github.com/JedWatson/classnames).
     */
    containerClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * Custom CSS classes for the modal's body. This is the element that has overflow rules and should be used to set a static height if desired. Use `classNames` [API](https://github.com/JedWatson/classnames).
     */
    contentClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * Custom styles for the modal's body. This is the element that has overflow rules and should be used to set a static height if desired.
     */
    contentStyle: PropTypes.object,

    /**
     * If true, modal footer buttons render left and right. An example use case would be for "back" and "next" buttons.
     */
    directional: PropTypes.bool,

    /**
     * If true, Modals cannot be dismissed by clicking on the close icon or pressing esc key.
     */
    disableClose: PropTypes.bool,

    /**
     * If true, Modals can be dismissed by clicking outside of modal. If unspecified, defaults to disableClose.
     */
    dismissOnClickOutside: PropTypes.bool,

    /**
     * Callback to fire with Modal is dismissed
     */
    onRequestClose: PropTypes.func,

    /**
     * Accepts a node or array of nodes that are typically a `Button` or `ProgressIndicator`. If an array, the nodes render on the right side first but are then floated left and right if <code>directional</code> prop is `true`.
     */
    footer: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),

    /**
     * Allows for a custom modal header that does not scroll with modal content. If this is defined, `heading` and `tagline` will be ignored. The close button will still be present.
     */
    header: PropTypes.node,

    /**
     * Adds CSS classes to the container surrounding the modal header and the close button. Use `classNames` [API](https://github.com/JedWatson/classnames).
     */
    headerClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * Unique identifier for the modal. The id is automatically generated if not provided
     */
    id: PropTypes.string,

    /**
     * Forces the modal to be open or closed.
     */
    isOpen: PropTypes.bool.isRequired,

    /**
     * Function whose return value is the mount node to insert the Modal element into. The default is `() => document.body`.
     */
    parentSelector: PropTypes.func,

    /**
     * Custom CSS classes for the portal DOM node. This node is a direct descendant of the `body` and is the parent of `ReactModal__Overlay`. Use `classNames` [API](https://github.com/JedWatson/classnames).
     */
    portalClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * Styles the modal as a prompt.
     */
    prompt: PropTypes.oneOf(['success', 'warning', 'error', 'wrench', 'offline', 'info']),

    /**
     * Specifies the modal's width. May be deprecated in favor of `width` in the future.
     */
    size: PropTypes.oneOf(['small', 'medium', 'large']),

    /**
     * Content underneath the heading in the modal header.
     */
    tagline: PropTypes.node,

    /**
     * Content underneath the title in the modal header.
     */
    title: PropTypes.node,

    /**
     * Text heading at the top of a modal.
     */
    heading: PropTypes.node,

    /**
     * Allows adding additional notifications within the modal.
     */
    toast: PropTypes.node
};

let slds = {
    DataTable,
    DataTableColumn,
    DataTableCell,
    Card,
    CardEmpty,
    Icon: SldsIcon,
    ButtonGroup,
    ButtonStateful,
    Dropdown,
    IconSettings,
    PageHeader,
    SplitView,
    SplitViewHeader,
    SplitViewListbox,
    Tooltip,
    DropdownTrigger
};

export default slds;