import {Modal} from "../../common/slds";
import {Formik} from "formik";
import {Log} from "../../common/log";
import {Form} from "../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import * as React from "react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATE_CREATE_DEVICE} from "../queries";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import PropTypes from "prop-types";
import {useHistory} from "react-router";
import OrganisationLookupField from "../../components/organisation/organisationLookupField";
import DeviceTypeLookupField from "../../components/deviceType/deviceTypeLookupField";
import {QUERY_DEVICE_TYPES} from "../../deviceTypes/queries";
import {useT} from "../../common/i18n";

const AddHardwareDialog = (props) => {
    let {isOpen, onRequestClose, onAdded, initialValues} = props;
    const t = useT();
    const history = useHistory();
    initialValues = {...initialValues}; // Make a copy
    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES, {
        fetchPolicy: "network-only"
    });

    const [createHardware] = useMutation(MUTATE_CREATE_DEVICE, {
        onCompleted: (data) => onAdded && onAdded(data.createDevice)
    });

    const loadingDT = useGraphqlLoadingComponent(deviceTypesResult);
    if (loadingDT) {
        return loadingDT;
    }
    const deviceTypes = deviceTypesResult.data.deviceTypes;

    if (initialValues) {
        initialValues.deviceTypeId = initialValues?.deviceType?.id;
        initialValues.organisationId = initialValues?.organisation?.id;
        initialValues.addr = "";
    }
    Log.Debug("Initial values:", initialValues);

    return <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Formik
            initialValues={initialValues || {}}
            validate={(values) => {
                const errors = {};
                if (values.deviceType && values.organisation) {
                    if (values.deviceType.private && values.organisation.id != values.deviceType.organisationId) {
                        errors.deviceType = 'DeviceType not accessible for Organisation: ' + values.organisation.id
                    }
                }
                return errors;
            }}
            onSubmit={(values, actions) => {
                // Take over certain fields from other device
                //Log.Debug("values:", values);

                createHardware({
                    variables: {
                        input: {
                            addr: values.addr,
                            name: values.name,
                            deviceTypeId: values.deviceType?.id,
                            organisationId: values.organisation?.id,
                            appId: values?.app?.id,
                            firmwareVersion: values?.firmwareVersion,
                            comment: values.comment,
                        }
                    }
                }).then((res) => {
                    const newDeviceId = res.data.createDevice.id;
                    history.push('/configuration/hardware/' + newDeviceId);
                    onRequestClose();
                }, (e) => {
                    actions.setFieldError("global", e);
                    Log.Error("Failed to create hardware", e);
                }).finally(() => {
                    actions.setSubmitting(false);
                });
            }}>
            <Form>
                <SldsInputField name={"addr"} label={"Address"} placeholder={"Device Address"} required={true}
                                autoFocus={true}/>
                <SldsInputField name={"name"} label={"Name"}/>
                <DeviceTypeLookupField/>
                <OrganisationLookupField/>
                <FormActions>
                    <SubmitButtonField>{t("common.button.create", "Create")}</SubmitButtonField>
                    <CancelButtonField onClick={onRequestClose}/>
                </FormActions>
            </Form>
        </Formik>
    </Modal>;
};

AddHardwareDialog.propTypes = {
    initialValues: PropTypes.any,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onAdded: PropTypes.func,
};

export default AddHardwareDialog;