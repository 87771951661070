import {Field, FieldArray, getIn} from "formik";
import PropTypes from "prop-types";
import React from "react";
import PillContainer from "../../slds/pills/pillContainer";
import PillContainerItem from "../../slds/pills/pillContainerItem";
import Pill from "../../slds/pills/pill";
import {SldsFormElementComponent} from "./formElements";
import ListBox from "../../slds/pills/ListBox";

/**
 * Renders a list of pills that can be added (e.g. via a Lookup) and removed from form state
 */
export const PillContainerField = (props) => {
    const {name, label, pillLabelExtractor, pillPropertiesExtractor, readOnly, canRemove, renderLookup} = props;
    return <Field name={name}>
        {(formik) => {
            const {field, form} = formik;
            return <SldsFormElementComponent
                name={name}
                label={label}
                readOnly={readOnly}
                renderControl={() => {
                    return <FieldArray name={name}
                                       render={arrayHelpers => {
                                           //Log.Debug("getIn(form.values, name)", name, getIn(form.values, name))
                                           return <PillContainer>
                                               {(getIn(form.values, name) || []).map((it, i) => {
                                                   let pillProps = {};
                                                   if (pillPropertiesExtractor && typeof pillPropertiesExtractor === 'function') {
                                                       pillProps = pillPropertiesExtractor(it);
                                                   }
                                                   return <PillContainerItem key={i}>
                                                       <Pill label={pillLabelExtractor(it)}
                                                             {...pillProps}
                                                             onRemove={canRemove ? (e) => {
                                                                 arrayHelpers.remove(i);
                                                             } : undefined}
                                                       />
                                                   </PillContainerItem>;
                                               })}
                                               {renderLookup ? <li className="slds-listbox-item" role="presentation">
                                                   {renderLookup(formik)}
                                               </li> : null}

                                           </PillContainer>;

                                       }}/>;
                }}
                renderControlReadOnly={() => {
                    return <ListBox>
                        {(getIn(form.values, name) || []).map((it, i) => {
                            let pillProps = {};
                            if (pillPropertiesExtractor && typeof pillPropertiesExtractor === 'function') {
                                pillProps = pillPropertiesExtractor(it);
                            }
                            return <PillContainerItem key={i} className="slds-m-horizontal--none"><Pill label={pillLabelExtractor(it)} {...pillProps} /></PillContainerItem>;
                        })}
                    </ListBox>;
                }}

            />;

        }}
    </Field>;
}

PillContainerField.defaultProps = {
    canRemove: true,
};

PillContainerField.propTypes = {
    name: PropTypes.string.isRequired, // Name of the form field array
    label: PropTypes.string,
    pillLabelExtractor: PropTypes.func.isRequired,
    pillPropertiesExtractor: PropTypes.func,
    readOnly: PropTypes.bool,
    canRemove: PropTypes.bool,
    // You can render a lookup to add more items the the list
    // The functions gets the formik FieldProps as first argument
    renderLookup: PropTypes.func,
};