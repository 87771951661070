import {Button} from "@salesforce/design-system-react";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import * as log from "../../../common/log";
import {Form} from "../../../common/ui/form/formik";
import React, {useContext} from "react";
import {Field, Formik} from "formik";
import {FormActions, SldsInput, SubmitButtonField} from "../../../common/ui/form/formElements";
import {AppContext} from "../../appPage";


export default function NewAlarmConfigForm(props) {
    const {onCreateAlarmConfig, result} = props;
    const app = useContext(AppContext);
    // Preselect generic device
    const defaultDeviceType = result.data.deviceTypes.find((t => t.name === 'generic')) || {};

    return <div>
        <Formik
            initialValues={{
                appId: app.id,
                deviceType: defaultDeviceType
            }}
            validate={(values, props) => {
                let errors = {};
                if (!values.name) {
                    errors.name = "Name must not be empty"
                }
                return errors
            }}
            onSubmit={(values, actions) => {
                log.Debug("Submit: ", values, actions);
                onCreateAlarmConfig(values, actions).then(() => {
                    actions.setSubmitting(false);
                }, (err) => {
                    alert("Failed to create alarm config");
                    actions.setSubmitting(false);
                });
            }}
        >
            <Form stacked={true}>
                <Field component={SldsInput} name="name" placeholder="Name of the alarm" id="name" label="Name" autoFocus={true}/>
                <Field component={SldsInput} name="triggerJs" placeholder="Condition (e.g. data.sensors[1].temperature > 60.0)" id="condition" label="Condition"/>

                <FormActions>
                    <SubmitButtonField type="submit">Create</SubmitButtonField>
                    <Link to={".."} className={"slds-m-left--x-small"}><Button variant="destructive">Cancel</Button></Link>
                </FormActions>
            </Form>

        </Formik>

    </div>
}

NewAlarmConfigForm.propTypes = {
    onCreateAlarmConfig: PropTypes.func.isRequired,
    // GraphQL result with info needed to create a device like all DeviceTypes
    result: PropTypes.object.isRequired,
}