import React from "react";
import DeviceDataPage from "./deviceData/deviceDataPage";
import {Redirect, Route} from "react-router-dom";
import DevicesPage from "./devices/devicesPage";
import DeviceDetailPage from "./devices/device/deviceDetailPage";
import {IntegrationsPage} from "./integrations/integrationPage";
import OrgConfigPage from "./config/orgConfigPage";
import {Switch} from "react-router";

const OrganisationPage = (props) => {
    return <Switch>
        <Route path={"/organisation/deviceData"} component={DeviceDataPage}/>
        <Route exact path={"/organisation/devices"} render={() => <Redirect to={'/organisation/devices/type/'}/>}/>
        <Route exact path={"/organisation/devices/type"} component={DevicesPage}/>
        <Route exact path={"/organisation/devices/type/:devTypeId"} component={DevicesPage}/>
        <Route path={"/organisation/devices/:deviceId"} component={DeviceDetailPage}/>
        <Route path={"/organisation/integrations"} component={IntegrationsPage}/>
        <Route path={"/organisation/config"} component={OrgConfigPage}/>
    </Switch>;
};

export default OrganisationPage;