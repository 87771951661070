import {useState} from "react";
import {usePagination} from "../../common/hooks/usePagination";
import {useApolloClient} from "@apollo/react-hooks";
import {ButtonGroup, Tabs, TabsPanel} from "@salesforce/design-system-react";
import {Formik} from "formik";
import {Form} from "../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsCheckboxField, SubmitButtonField} from "../../common/ui/form/formElements";
import OrganisationLookupField from "../../components/organisation/organisationLookupField";
import DeviceTypeLookupField from "../../components/deviceType/deviceTypeLookupField";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import Tooltip from "../../common/slds/tooltip/tooltip";
import {Icon} from "../../common/slds/icons/icon";
import Page from "../../common/ui/page";
import {Link} from "react-router-dom";
import * as React from "react";
import gql from "graphql-tag";
import Button from "../../common/slds/buttons/button";


const MUTATE_UPDATE_DEVICE = gql`
    mutation($id: ID!, $input: DeviceInput!) {
        updateDevice(id: $id, input: $input) {
            id
            addr
            name
            description
            app {
                id
                name
            }
            organisation {
                id
                name
            }
            deviceType {
                id
                displayName
            }
            propertiesRaw
            initialConfigRaw
            serial
        }
    }`;

export const HardwareBulkOperationPage = (props) => {
    let {multiSelection, setShowBulkOperationPage} = props;
    const [selectedTab, setSelectedTab] = useState(0);
    const page = usePagination();

    let resultMap = new Map()
    const client = useApolloClient();
    let operationTab = <TabsPanel label="Bulk Operation">
        <Formik
            initialValues={
                {
                    changeOrganisation: false,
                    changeDeviceType: false,
                }
            }
            onSubmit={(values, actions) => {
                // Take over certain fields from other device
                //Log.Debug("values:", values);
                let input = {}
                if (values.changeOrganisation) {
                    input.organisationId = values.organisation?.id || 0
                }
                if (values.changeDeviceType) {
                    input.deviceTypeId = values.deviceType.id
                }
                let deviceMutations = []
                let updatedDevices = []
                props.multiSelection.selections.forEach((i) => {
                    let m = client.mutate({
                        mutation: MUTATE_UPDATE_DEVICE,
                        variables: {
                            id: i.id,
                            input: input,
                        }
                    }).then((res) => {
                        resultMap.set(i.id, res.errors)
                        updatedDevices.push(res.errors ? i : res.data.updateDevice)
                    })
                    deviceMutations.push(m)
                })

                Promise.all(deviceMutations).then(() => {
                    multiSelection.setSelections(updatedDevices)
                    setSelectedTab(1)
                })
            }}>
            <Form withPadding={true}>
                <SldsCheckboxField className="slds-m-top--x-small" name={"changeOrganisation"}
                                   inlineLabel="Change Organisation:"/>
                <OrganisationLookupField/>
                <SldsCheckboxField className="slds-m-top--x-small" name={"changeDeviceType"}
                                   inlineLabel="Change Device Type:"/>
                <DeviceTypeLookupField/>
                <FormActions>
                    <SubmitButtonField>Bulk Update</SubmitButtonField>
                    <CancelButtonField onClick={() => setShowBulkOperationPage(false)}/>
                </FormActions>
            </Form>
        </Formik>

        <br/>
        Devices to Update:
        <GenericDataTable id={"bulk-operation-table"} items={multiSelection.selections} page={page}
                          tableConfigDefault={{}}>
            <DataTableColumn label="Serial" property={"serial"}
                             render={(props) => {return props.item.serial}}/>
            <DataTableColumn label="Address" property="addr" title={"Addr"}
                             render={(props) => {return props.item.addr}}/>
            <DataTableColumn label="Name" property="name" title={"Name"}
                             render={(props) => {return props.item.name}}/>
            <DataTableColumn label="Device Type" property={"deviceType"}
                             render={(props) => {return props.item.deviceType.displayName}}/>
            <DataTableColumn label="App" property={"app"}
                             render={(props) => {return props.item.app ? props.item.app.name : null}}/>
            <DataTableColumn label="Organization" property={"Organization"}
                             render={(props) => {return props.item.organisation ? props.item.organisation.name : ""}}/>
        </GenericDataTable>
    </TabsPanel>

    let resultTab = <TabsPanel label="Results">
        <GenericDataTable id={"bulk-result-table"} items={multiSelection.selections} page={page}
                          tableConfigDefault={{}}>
            <DataTableColumn label="Serial" property={"serial"}
                             render={(props) => {return props.item.serial}}/>
            <DataTableColumn label="Address" property="addr" title={"Addr"}
                             render={(props) => {return props.item.addr}}/>
            <DataTableColumn label="Name" property="name" title={"Name"}
                             render={(props) => {return props.item.name}}/>
            <DataTableColumn label="Device Type" property={"deviceType"}
                             render={(props) => {return props.item.deviceType.displayName}}/>
            <DataTableColumn label="App" property={"app"}
                             render={(props) => {return props.item.app ? props.item.app.name : null}}/>
            <DataTableColumn label="Organization" property={"Organization"}
                             render={(props) => {return props.item.organisation ? props.item.organisation.name : ""}}/>
            <DataTableColumn label="Result" property={"Result"}
                             render={(props) => {
                                 return <Tooltip left="-10px" top="-50px"
                                     content={resultMap.get(props.item.id) ? resultMap.get(props.item.id) : "Success"}>
                                     {resultMap.get(props.item.id) ? <Icon name="close" size={"small"}/> :
                                     <Icon name="check" size={"small"}/>}
                                 </Tooltip>
            }}/>
        </GenericDataTable>
    </TabsPanel>


    return <Page
        title={"Hardware"}
        actions={
            selectedTab == 0 ?
                <Button iconName={"close"} onClick={() => setShowBulkOperationPage(false)}>Cancel</Button>
                : <ButtonGroup>
                    <Button iconName={"redo"} onClick={() => setSelectedTab(0)}>Retry</Button>
                    <Button iconName={"check"} onClick={() => setShowBulkOperationPage(false)}>Finish</Button>
                </ButtonGroup>
        }
        trail={[<Link to="./hardware" key={1}>Hardware Bulk Operation Wizard</Link>]}>
        <Tabs id="tabs-example-default" selectedIndex={selectedTab}>
            {operationTab}
            {resultTab}
        </Tabs>

    </Page>;
}

