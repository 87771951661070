import {Field, Formik} from "formik";
import React, {useState} from "react";
import {FormActions, SldsCheckboxField, SldsInputField} from "../common/ui/form/formElements";
import {Form} from "../common/ui/form/formik";
import * as yup from "yup";
import {Button} from "@salesforce/design-system-react";
import {useMutation} from "@apollo/react-hooks";
import {useHistory, useLocation} from "react-router-dom";
import gql from "graphql-tag";
import {getQueryStringParams} from "../common/helper";
import PlatformLogo from "../PlatformLogo";

const RegistrationPage = (props) => {
    const [registrationFailed, setRegistrationFailed] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const [mutateCreateUser] = useMutation(gql`
        mutation ($input: UserInput) {
            createUser(input: $input) {
                id
            }
        }`, {}
    );

    return <>
        <div className="slds-grid slds-grid--align-center">
            <div className="slds-p-top--large">
                <div className="slds-p-around--medium">
                    <PlatformLogo className="slds-global-header__logo slds-m-bottom--medium" />
                    <p className="slds-text-heading--medium">Register</p>
                </div>
                <div className="slds-card slds-p-around--medium">
                    {registrationFailed &&
                    <div style={{color: "rgb(194, 57, 52)"}}>
                        Registration failed. please try again.
                    </div>
                    }
                    <Formik
                        initialValues={{}}
                        validationSchema={yup.object().shape({
                            login: yup.string().lowercase().matches(/^[A-Za-z0-9\-]+$/, "login must contain only: A-Z, a-z, 0-9, -"),
                            email: yup.string()
                                .label('Email')
                                .email()
                                .required(),
                            password: yup
                                .string()
                                .label('Password')
                                .required()
                                .min(6, 'Must be at least 6 characters')
                                .max(20, 'Can have max. 20 characters'),
                            confirmPassword: yup
                                .string()
                                .required()
                                .label('Confirm password')
                                .test('passwords-match', 'Passwords do not match', function (value) {
                                    return this.parent.password === value;
                                })
                        })}
                        onSubmit={(values, actions) => {
                            mutateCreateUser({
                                variables: {
                                    input: {
                                        email: values.email,
                                        login: values.login || values.email,
                                        name: values.name,
                                        company: values.company,
                                        password: values.password
                                    }
                                }
                            }).then(result => {
                                if (result.error) {
                                    setRegistrationFailed(true);
                                } else {
                                    const queryStringParams = getQueryStringParams(location.search);
                                    const redirectTo = queryStringParams.r || "/";
                                    history.push("/auth/login?r=" + redirectTo);
                                }
                            }).finally(() => {
                                actions.setSubmitting(false);
                            })
                        }}>
                        <Form>
                            <SldsInputField name={"email"} label={"E-Mail"} placeholder={"e.g. user@example.com"} required={true}/>
                            <SldsInputField name={"login"} label={"Username / Login"} required={false}/>
                            <SldsInputField name={"name"} label={"Full Name"} placeholder={"e.g. Max Mustermann"} required={true}/>
                            <SldsCheckboxField name={"joinOrg"} inlineLabel={"Join existing Organisation"} className="slds-m-top--x-small"/>
                            <SldsInputField name={"company"} label={"Organisation"} placeholder={"e.g. Lobaro GmbH"}/>
                            <SldsInputField name={"password"} label={"Password"} type="password" required={true}/>
                            <SldsInputField name={"confirmPassword"} label={"Confirm Password"} type="password" required={true}/>
                            <FormActions>
                                <Field render={(formik) => <Button
                                    type="submit" className="slds-button--brand"
                                    disabled={formik.form.isSubmitting} {...props}>Create Account</Button>}
                                />
                            </FormActions>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    </>;
};

export default RegistrationPage;