import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import Page from "../../common/ui/page";
import {Formik} from "formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import {Form} from "../../common/ui/form/formik";
import * as Log from "../../common/log";
import {YesNoDialog} from "../../common/slds/modals/YesNoDialog";
import {useHistory} from "react-router";
import Button from "../../common/slds/buttons/button";
import ButtonGroup from "../../common/slds/buttonGroup/ButtonGroup";
import {OrganisationUserList} from "../../components/organisation/OrganisationUserList";
import roles from "../../model/roles";

const OrganisationDetailPage = (props) => {
    const history = useHistory();
    const organisationResult = useQuery(gql`
        query($id: ID!) {
            getOrganisation(id: $id) {
                id
                name
                numUsers
                numDevices
                createdAt
                dataRetentionTime
            }         
        }
    `, {
        fetchPolicy: "network-only",
        variables: {
            id: props.match.params.id
        }
    });

    const [mutateUpdateOrga] = useMutation(gql`
        mutation($id: ID!, $input: OrganisationInput) {
            updateOrganisation(id: $id, input: $input) {
                id
            }
        }
    `);

    const [mutateDeleteOrga] = useMutation(gql`
        mutation($id: ID!) {
            deleteOrganisation(id: $id)
        }
    `);


    const deletePending = useState(false);

    const loading = useGraphqlLoadingComponent(organisationResult);
    if (loading) {
        return loading;
    }

    const organisation = organisationResult.data.getOrganisation;

    const evaluateRetentionTime = (newRetentionTime) => {
        if (newRetentionTime != organisation.dataRetentionTime) {
            return newRetentionTime
        }
        return null
    }

    return <Page
        trail={[
            <Link to="." key={1}>Organisations</Link>,
            <Link to={"./" + props.match.params.id} key={2}>{organisation.name}</Link>]
        }
        title={organisation.name}
        info={<>{organisation.numUsers} Users • {organisation.numDevices} Devices </> }
        actions={<ButtonGroup>
            {/*Not Implemented: <Button iconName={"adduser"}>Invite User</Button>*/}
            {organisation.numUsers === 0 && organisation.numDevices === 0 ? <Button iconName={"delete"} className="slds-button--destructive" onClick={() => deletePending[1](true)}>Delete Organsiation</Button> : null}
        </ButtonGroup>}
    >
        <Formik
            initialValues={{...organisation}}
            initialStatus={{
                readOnly: true,
                canEdit: true
            }}
            onSubmit={(values, actions) => {
                mutateUpdateOrga({
                    variables: {
                        id: props.match.params.id,
                        input: {
                            name: values.name,
                            dataRetentionTime: evaluateRetentionTime(values.dataRetentionTime)
                        }
                    }
                }).then(() => {
                    actions.setStatus({canEdit: true, readOnly: true});
                    organisationResult.refetch();
                }).catch((err) => {
                    Log.Error("Failed to update organisation", err);
                    actions.setFieldError("global", "Failed to update organisation: " + err.message);
                }).finally(() => {
                    actions.setSubmitting(false);
                })
            }}
        >
            <Form className="slds-m-around--small">
                <SldsInputField name={"id"} label={"ID"} />
                <SldsInputField name={"name"} label={"Name"} />
                <SldsInputField name={"dataRetentionTime"} label={"Data Retention Time"}/>

                <FormActions>
                    <SubmitButtonField>Save</SubmitButtonField>
                    <CancelButtonField>Cancel</CancelButtonField>
                </FormActions>
            </Form>
        </Formik>

        <YesNoDialog title={"Delete Organisation"} text={"Delete organisation permanently?"} onYes={() => {
            mutateDeleteOrga({variables: {id: organisation.id}})
                .then(res => {
                   history.push("/configuration/organisations");
                });
        }} open={deletePending}/>

        <OrganisationUserList organisationId={organisation.id}/>

    </Page>;
};

export default OrganisationDetailPage;


