import {Link, useRouteMatch} from "react-router-dom";
import Page from "../../../common/ui/page";
import React from "react";
import {useAuthContext} from "../../../common/context/authContext";
import {OrganisationUserList} from "../../../components/organisation/OrganisationUserList";
import {useT} from "../../../common/i18n";

export const OrganisationUsersPage = (props) => {
    const t = useT();
    const match = useRouteMatch();
    const auth = useAuthContext();

    return <Page
        trail={[<Link to={match.url} key={1}>{t("org.config.nav.users", "Users")}</Link>]}
        title={t("org.config.users.title", "Users")}>
      <OrganisationUserList organisationId={auth.organisationId().toString()}/>
  </Page>;
}