import Page from "../../common/ui/page";
import {Link} from "react-router-dom";
import {ButtonGroup} from "@salesforce/design-system-react";
import * as React from "react";
import {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {QUERY_ACTIVATION_GROUP_LIST} from "../queries";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import CustomDataTableCell from "../../common/ui/data-table/customCell";
import moment from "moment";
import AddActivationGroupDialog from "./addActivationGroupDialog";
import {usePagination} from "../../common/hooks/usePagination";
import {useHistory} from "react-router";
import Button from "../../common/slds/buttons/button";
import {useSearch} from "../../common/hooks/useSearch";

const ActivationGroupsListPage = (props) => {
    const page = usePagination(25);
    const history = useHistory();
    const search = useSearch();
    const [showNewActivationGroupModal, setShowNewActivationGroupModal] = useState(false);

    const activationGroupListResult = useQuery(QUERY_ACTIVATION_GROUP_LIST, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput(),
        }
    });

    const activationGroups = activationGroupListResult.data?.getActivationGroupList;
    return <Page
        trail={[<Link to="." key={1}>Activation Groups</Link>]}
        title={"Activation Groups"}
        actions={<ButtonGroup><Button iconName={"add"} onClick={() => setShowNewActivationGroupModal(true)}>New Activation Group</Button></ButtonGroup>}
    >
        <AddActivationGroupDialog isOpen={showNewActivationGroupModal} onRequestClose={() => setShowNewActivationGroupModal(false)} onAdded={(id) => history.push("/configuration/activationGroups/" + id)}/>
        <GenericDataTable id={"activation-groups-table"} items={activationGroups} tableConfigDefault={{}} gqlResult={activationGroupListResult} page={page} search={search}>
            <DataTableColumn label="ID" property={"id"}>
                <CustomDataTableCell key="id" render={(props) => (
                    <Link to={`./activationGroups/${props.item.id}`}>{props.item.id}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label="Name" property="nr">
                <CustomDataTableCell key="id" render={(props) => (
                    <Link to={`./activationGroups/${props.item.id}`}>{props.item.nr}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label={"Created"} property={"createdAt"} title={"createdAt"}>
                <CustomDataTableCell render={(props) => {
                    return <div className="slds-cell-wrap">{moment(props.item.createdAt).fromNow()} ({moment(props.item.createdAt).format('DD.MM.YYYY HH:mm:ss')})</div>;
                }}/>
            </DataTableColumn>
        </GenericDataTable>
    </Page>;
};

export default ActivationGroupsListPage;
