import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames'
import slds from "../slds";


export default class Box extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.any,
        iconType: PropTypes.oneOf(["info"])
    };

    static defaultProps = {
        iconType: "info",
    };

    render() {
        const {children, className} = this.props;

        let icon = <slds.Icon
            category="utility"
            name="info"
            size="small"
            className="slds-m-right--small"
        />;

        return <div className={classNames("slds-box", className)}>
            <div className="slds-inline_icon_text slds-grid">
                {icon}
                <div className="slds-col slds-align-middle">{children}</div>
            </div>
        </div>
    }
};

