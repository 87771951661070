import React, {useContext} from "react";
import PropTypes from "prop-types";
import {backendUrl} from "../common/helper";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {Log} from "../common/log";
import {useAuthContext} from "../common/context/authContext";
import {FrontendConfigContext} from "../common/context/frontendConfigContext";

const QUERY_ORGANISATION = gql`
    query ($orgId: ID!) {
        organisation: getOrganisation(id: $orgId) {
            id
            name
            logoUrl
        }
    }
`;

export default function HeaderOrganisationLogo(props) {
    const {logoUrl} = props;

    const authCtx = useAuthContext();
    const orgResult = useQuery(QUERY_ORGANISATION, {
        fetchPolicy: "cache-first",
        variables: {
            orgId: authCtx.organisationId(),
        }
    });

    const org = orgResult.data?.organisation;

    const brandingLogo = React.useContext(FrontendConfigContext)?.brandingConfig?.defaultLogoUrl;
    var logoCssUrl;
      if (brandingLogo) {
        logoCssUrl = `url(${backendUrl()}${brandingLogo})`;
    }
    if (org?.logoUrl) {
        logoCssUrl = `url(${backendUrl()}/${org?.logoUrl})`;
    }
    if(logoCssUrl) {
        return <div className="slds-global-header__logo"
                    style={{backgroundImage: logoCssUrl}}/>;
    } else {
        return <div className="no_logo_found__logo"/>
    }
}

HeaderOrganisationLogo.propTypes = {
    logoUrl: PropTypes.string,
};