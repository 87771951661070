import {Log} from "./log";

let backendUrl = window.location.origin;

// Hack for development setup
if (window.location.port === "3000") {
    Log.Info("Detected development setup on port :3000, redirecting backend URL to :8082")
    backendUrl = backendUrl.replace("3000", "8082")
}

export const platformConfig = {
    "backendUrl": backendUrl
};