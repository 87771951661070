import {FieldArray, Formik, insert} from "formik";
import React from "react";
import * as Yup from "yup";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "./queries";
import {Form} from "../common/ui/form/formik";
import {CancelButtonField, FormActions, Option, SldsCheckboxField, SldsFormElementCompound, SldsInputField, SldsSelectField, SldsTextareaField, SubmitButtonField} from "../common/ui/form/formElements";
import {Log} from "../common/log";
import Lookup from "../common/ui/lookup/lookup";
import {PillContainerField} from "../common/ui/form/pillContainerField";
import _ from "underscore";
import Button from "../common/slds/buttons/button";
import {ConfigPropertyType} from "../model/device";
import {useAuthContext} from "../common/context/authContext";
import SingleLookupField from "../common/ui/lookup/singleLookupField";
import gql from "graphql-tag";

const TRAITS = [
    {
        type: "nb-iot-fota",
        displayName: "NB-IoT Firmware Update",
        description: "Allows Firmware updates via NB-IoT"
    },
    {
        type: "remote-config",
        displayName: "Remote Config Update",
        description: "Allows remote config updates"
    },
    {
        type: "wmbus-data",
        displayName: "wMbus Data",
        description: "Device can parse, decrypt and display wMbus Data"
    },
];

function getTrait(type) {
    return TRAITS.find(it => it.type === type) || {type: type, displayName: "Unknown: " + type};
}

function evaluateOrganisation(initialDeviceType, valuesAfterEdit, auth) {
    if (!auth.hasRole("admin")) {
        return null
    }
    let orgId = valuesAfterEdit.organisation ? valuesAfterEdit.organisation.id : 0
    if (initialDeviceType.organisationId == orgId) {
        return null
    }
    return orgId
}

const UpdateDeviceTypeDialog = (props) => {
    const auth = useAuthContext();
    const deviceType = props.deviceType;

    const organisationListResult = useQuery(gql`
        query($search: String) {
            getOrganisationList(search: $search) {
                id
                name
            }
        }`, {
        fetchPolicy: "network-only",
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        }
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceType.id},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceType.id,
            }
        }]
    });

    let configProperties = [];
    if (deviceType.configProperties) {
        let parsedProps = JSON.parse(deviceType.configProperties);
        if (_.isArray(parsedProps)) {
            configProperties = parsedProps;
        }

        // Only for backwards compatibility of old format
        if (!_.isArray(parsedProps)) {
            for (let p in parsedProps) {
                configProperties.push({
                    name: p,
                    displayName: parsedProps[p].displayName,
                    type: parsedProps[p].type,
                    visible: parsedProps[p].visible,
                    exported: parsedProps[p].exported
                });
            }
        }
    }
    Log.Debug("configProperties", configProperties, deviceType.configProperties);
    const canEdit = auth.hasRole("admin") || (deviceType.organisationId == auth.organisationId() && auth.hasRole("org-admin"));
    return <Formik
        initialValues={{
            ...deviceType,
            private: deviceType.private.toString(),
            configProperties: configProperties,
        }}
        initialStatus={{
            readOnly: true,
            canEdit: canEdit,
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().strict().shape({
            displayName: Yup.string().required().trim(),
        })}
        onSubmit={(values, actions) => {
            updateDeviceType({
                variables: {
                    id: deviceType.id,
                    input: {
                        displayName: values.displayName,
                        description: values.description,
                        configProperties: JSON.stringify(values.configProperties),
                        deviceTraits: values.deviceTraits,
                        organisationId: evaluateOrganisation(deviceType, values, auth),
                        private: values.private === "true"
                    }
                }
            }).then(() => {
                actions.setStatus({canEdit: true, readOnly: true});
            }).catch((err) => {
                Log.Error("Failed to save device type:", err);
                alert("Failed to save device type.");
            }).finally(() => {
                actions.setSubmitting(false);
            });
        }}
        render={(formik) => {
            return <Form className="slds-m-around--x-small" >
                <SldsInputField name={"displayName"} label={"Display Name"}/>
                <SldsTextareaField name={"description"} label={"Description"} cols={3}/>
                <SldsSelectField name={"private"} label={"Private"} options={[
                    {
                        "key": "true",
                        "value": "true",
                        "label": "Private",
                    },
                    {
                        "key": "false",
                        "value": "false",
                        "label": "Public",
                    }
                ]}/>
                <SingleLookupField name={"organisation"} label={"Organisation (optional)"} autoFocus={false} required={false}
                                                              loadSuggestions={(keyword) => organisationListResult
                                                                  .refetch({search: keyword})
                                                                  .then(result => result.data?.getOrganisationList)
                                                              }
                                                              titleExtractor={it => it.name}
                                                              subtitleExtractor={it => it.id}
                                                              readOnly={!auth.hasRole("admin")}
                />

                <SldsFormElementCompound label="Traits">
                    {/* TODO: Use PillContainerField */}
                    <PillContainerField name={"deviceTraits"} pillLabelExtractor={it => getTrait(it).displayName} renderLookup={() => {
                        return <Lookup placeholder={"Add Trait ..."}
                                       titleExtractor={item => item && getTrait(item).displayName}
                                       subtitleExtractor={item => item}
                                       onLookup={(value) => {
                                           let list = formik.values["deviceTraits"] || [];
                                           formik.setFieldValue("deviceTraits", insert(list, list.length, value));
                                       }}
                                       loadSuggestions={(value) => {
                                           Log.Debug("Suggestions", value);
                                           const search = value.toLowerCase();
                                           return TRAITS.map((t) => {
                                               const displayName = getTrait(t.type).displayName.toLowerCase();
                                               const type = t.type.toLowerCase();

                                               if (type.includes(search) || displayName.includes(search)) {
                                                   return t.type;
                                               }
                                           })
                                               .filter(it => !!it)
                                               .filter(item => {
                                                   let traits = formik.values.deviceTraits || [];
                                                   return traits.findIndex(it => item === it) === -1;
                                               });
                                       }}
                        />;
                    }}/>
                </SldsFormElementCompound>
                <FieldArray name={"configProperties"}
                            render={arrayHelpers => {
                                return <SldsFormElementCompound label="Config Properties">
                                    {formik.values.configProperties.map((configProperty, index) => <div
                                        className="slds-form-element__row" key={index}>
                                        <div className="slds-size_3-of-8 slds-p-horizontal_x-small">
                                            <SldsInputField label="Name" name={`configProperties.${index}.name`}
                                                            required={true}/>
                                        </div>
                                        <div className="slds-size_2-of-8 slds-p-horizontal_x-small">
                                            <SldsInputField label="Display Name" name={`configProperties.${index}.displayName`}
                                                            required={false}/>
                                        </div>
                                        <div className="slds-size_1-of-8 slds-p-horizontal_x-small">
                                            <SldsSelectField label="Type" name={`configProperties.${index}.type`}
                                                             required={true} >
                                                {_.keys(ConfigPropertyType).map(key => (
                                                    <Option key={key}
                                                            value={ConfigPropertyType[key].value}>{ConfigPropertyType[key].label}</Option>))}
                                            </SldsSelectField>
                                        </div>
                                        <div className="slds-size_1-of-16">
                                            <SldsCheckboxField name={`configProperties.${index}.visible`}
                                                               label={"visible"}/>
                                        </div>
                                        <div className="slds-size_1-of-16">
                                            <SldsCheckboxField name={`configProperties.${index}.exported`}
                                                               label={"exported"}/>
                                        </div>
                                        <div className="slds-size_1-of-8">
                                            {formik.status.readOnly === false &&
                                            <Button variant={"icon"} iconCategory={"action"} iconName={"remove"}
                                                    iconSize={"small"}
                                                    onClick={() => {
                                                        arrayHelpers.remove(index);
                                                        Log.Debug("REMOVE", index, configProperties);
                                                    }}/>
                                            }
                                        </div>
                                    </div>)}
                                    {formik.status.readOnly === false &&
                                    <Button variant={"icon"} iconName={"add"} iconSize={"small"}
                                            onClick={() => {
                                                arrayHelpers.push({"name": "", "type": "Int32"});
                                            }}>Add Property</Button>
                                    }
                                </SldsFormElementCompound>;
                            }
                            }/>
                    <FormActions>
                        <SubmitButtonField/>
                        <CancelButtonField/>
                    </FormActions>
            </Form>
                ;
        }}
    />
        ;
};

export default UpdateDeviceTypeDialog;