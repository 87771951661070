import Page from "../../common/ui/page";
import * as React from "react";
import DataTableColumn from "../../common/ui/data-table/column";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import {useQuery} from "@apollo/react-hooks";
import {QUERY_CRON_LOG_LIST} from "../queries";
import {usePagination} from "../../common/hooks/usePagination";



const CronLogPage = (props) => {

    const page = usePagination();
    usePagination()

    const result = useQuery(QUERY_CRON_LOG_LIST, {
        variables: {
            page: page.getGraphqlPageInput(),
            sort: {field: "startTime", direction: "DESC"},
        }
    });
    const {data} = result;

    return  <Page
        title={"Cron Log"}
    >
      <GenericDataTable
          id={"cron-log-table"}
          items={data?.getCronLogList}
          gqlResult={result}
          tableConfigDefault={{}}
          page={page}
      >
          <DataTableColumn label="Job" property="jobName"/>
          <DataTableColumn label="Start Time" property="startTime"/>
          <DataTableColumn label="End Time" property="endTime"/>
          <DataTableColumn label="Error Occurred" property="errorOccurred"/>
          <DataTableColumn label="Execution Result" property="executionResult"/>
          <DataTableColumn label="Execution Params" property="executionParams"/>


      </GenericDataTable>
    </Page>

}

export default CronLogPage