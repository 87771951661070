import {ApolloProvider} from "react-apollo";
import {client} from "./common/graphql";
import {ApolloProvider as ApolloHooksProvider} from "@apollo/react-common";
import {IconSettings} from "@salesforce/design-system-react";
import AppPage from "./app/appPage";
import {HashRouter, Route, Switch} from "react-router-dom";
import AuthContextProvider from "./common/context/authContextProvider";
import ErrorBoundary from "./common/ui/errorBoundary";
import React from "react";
import { Suspense } from 'react';
import NonAppModule from "./nonAppModule";
import Playground from "./playground/playground";
import "./lobaro.css";
import {NotificationContextProvider} from "./notifications/notificationContext";
import FrontendConfigContextProvider from "./common/context/frontendConfigContext";

const Platform = (props) => <ErrorBoundary>
    <Suspense fallback="... loading">
        <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
                <IconSettings iconPath="/assets/icons">
                    <HashRouter hashType={"slash"}>
                        <NotificationContextProvider>
                            <FrontendConfigContextProvider>
                                <AuthContextProvider>
                                    <Switch>
                                        <Route path={"/playground"} component={Playground}/>
                                        <Route path={"/app/:appId"} component={AppPage}/>
                                        <Route component={NonAppModule}/>
                                    </Switch>
                                </AuthContextProvider>
                            </FrontendConfigContextProvider>
                        </NotificationContextProvider>
                    </HashRouter>
                </IconSettings>
            </ApolloHooksProvider>
        </ApolloProvider>
    </Suspense>
</ErrorBoundary>;

export default Platform;