import * as React from "react";
import {createRef} from "react";
import {Map, Marker, TileLayer} from "react-leaflet";
import {redIcon} from "../../../common/ui/leaflet/icons";
import {LeafletControl} from "../../../common/ui/leaflet/leafletControl";
import * as PropTypes from "prop-types";
import * as log from "../../../common/log";
import {HasPermission} from "../../../common/ui/permissions";

export default class DeviceMap extends React.Component {
    state = {
        editMode: false,
        position: [0, 0],
    };

    mapRef = createRef();

    static propTypes = {
        // [lon, lat]
        position: PropTypes.array.isRequired,
        onUpdateLocation: PropTypes.func.isRequired,
        zoom: PropTypes.number
    };

    constructor(props) {
        super(props);

        this.state.position = props.position;
        this.state.zoom = props.zoom || 13;
    }

    onViewportChanged = (viewport) => {
        // The viewport got changed by the user, keep track in state
        this.setState({zoom: viewport.zoom});
    };

    get map() {
        return (this.mapRef.current || {}).leafletElement;
    }

    updateDeviceLocation(e) {
        const {onUpdateLocation} = this.props;

        Promise.resolve(onUpdateLocation(e.latlng)).then(() => {
            this.setState({position: e.latlng});
        }, (err) => {
            log.Error("Failed to update device location:", err);
            alert("Failed to update device location.");
        }).finally(() => {
            this.setState({editMode: false});
        });
    }

    render() {
        //const {position} = this.props;
        const {editMode, position, zoom} = this.state;
        return <Map center={position}
                    zoom={zoom}
                    onViewportChanged={this.onViewportChanged}
                    ref={this.mapRef} attributionControl={false}
                    style={{height: "300px", cursor: editMode ? 'crosshair' : ''}}
                    onClick={(e) => {
                        if (this.state.editMode) {
                            this.updateDeviceLocation(e);
                        }
                    }}

        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution={""}
            />

            <HasPermission role={["admin", "org-admin"]}>
                <LeafletControl position="topright">
                    {!editMode ? <button
                            onClick={() => {
                                //this.map.setZoom(7);
                                this.setState({"editMode": true});
                            }
                            }
                        >
                            Edit Location
                        </button>
                        :
                        <>
                            <button
                                onClick={() => {
                                    this.updateDeviceLocation({latlng: {lat: 0, lng: 0}});
                                    this.setState({"editMode": false});
                                }
                                }
                            >
                                Delete Location
                            </button>
                            <button
                                onClick={() => {
                                    //this.map.setZoom(7);
                                    this.setState({"editMode": false});
                                }
                                }
                            >
                                Cancel Edit
                            </button>
                        </>}
                </LeafletControl></HasPermission>
            <Marker position={position}
                    icon={redIcon}

            >
                {/*<Popup>
                                    A pretty CSS3 popup. <br/> Easily customizable.
                                    </Popup>*/}
            </Marker>
        </Map>;
    }
}