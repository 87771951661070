import * as React from "react";
import {useContext, useState} from "react";
import gql from "graphql-tag";
import DescriptionList, {DescriptionListEntry} from "../../common/slds/descriptionList/descriptionList";
import {useAuthContext} from "../../common/context/authContext";
import {useNotificationContext} from "../../notifications/notificationContext";
import Roles from "../../model/roles";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Log} from "../../common/log";
import ScopedNotification from "../../common/slds/scopedNotifications/scopedNotification";
import Button from "../../common/slds/buttons/button";
import {Modal} from "../../common/slds";
import {Field, Formik} from "formik";
import {Form} from "../../common/ui/form/formik";
import {
    CancelButtonField,
    FormActions,
    ReactTimePicker,
    SldsCheckboxField,
    SldsFormElementCompound,
    SldsInputField,
    SubmitButtonField
} from "../../common/ui/form/formElements";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import moment from "moment";
import IntegrationFilterLookupField from "../../components/integration/integrationFilterLookupField";
import {FrontendConfigContext} from "../../common/context/frontendConfigContext";
import {useT} from "../../common/i18n";

const MUTATION_CREATE_INTEGRATION_SFTP = gql`
    mutation ($orgId: ID!, $input: IntegrationSftpInput) {
        integrationSftp: createIntegrationSftp(orgId: $orgId, input: $input){
            id
        }
    }
`;

const MUTATION_UPDATE_INTEGRATION_SFTP = gql`
    mutation ($id: ID!, $input: IntegrationSftpInput!) {
        integrationHttp: updateIntegrationSftp(id: $id, input: $input){
            id
        }
    }
`;

const MUTATION_DELETE_INTEGRATION_SFTP = gql`
    mutation ($id: ID!) {
        integrationSftp: deleteIntegrationSftp(id: $id){
            id
        }
    }
`;

const QUERY_INTEGRATION_SFTP = gql`
    query ($orgId: ID!) {
        integrationSftp: integrationSftpList(orgId: $orgId){
            id
            inputFilter {
                id
                name
            }
            url
            port
            filePath
            groupByTags
            username            
            password
            cron
            lastExportStatus            
            lastExport
            lastSuccessfulExport
        }
    }
`;

export default function SftpApiTab(props) {
    const t = useT();
    const auth = useAuthContext();
    const orgId = auth.organisationId();
    const canEdit = auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN);
    const notify = useNotificationContext();
    const [modalOpen, setModalOpen] = useState(false);
    const displayDocLinks = useContext(FrontendConfigContext)?.displayDocLinks

    const integrationSftpResult = useQuery(QUERY_INTEGRATION_SFTP, {
        variables: {
            "orgId": orgId,
        }
    });

    const [createIntegrationMutation] = useMutation(MUTATION_CREATE_INTEGRATION_SFTP, {
        variables: {
            "orgId": orgId,
        },
        refetchQueries: [{
            query: QUERY_INTEGRATION_SFTP,
            variables: {
                "orgId": orgId,
            },
        }]
    });

    const [updateIntegrationMutation] = useMutation(MUTATION_UPDATE_INTEGRATION_SFTP, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_SFTP,
            variables: {
                "orgId": orgId,
            },
        }]
    });

    const [deleteIntegrationMutation] = useMutation(MUTATION_DELETE_INTEGRATION_SFTP, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_SFTP,
            variables: {
                "orgId": orgId,
            },
        }]
    });

    const loading = useGraphqlLoadingComponent(integrationSftpResult);
    if (loading) {
        return loading;
    }

    Log.Debug("integrationSftpResult", integrationSftpResult);
    const {integrationSftp} = integrationSftpResult.data;

    return <div className="slds-m-horizontal--x-small">
        <p className="slds-m-bottom--medium">{t("integrations.sftp.explanation", "A SFTP Integration exports a CSV to a FTP Server on a daily basis.")}</p>
        {displayDocLinks ? <p className="slds-m-bottom--small"><a href={"https://doc.lobaro.com/x/4gDt"} target="_blank">{t("integrations.sftp.documentation-link", "SFTP Integration Documentation")}</a></p> : null}

        {integrationSftp.length === 0 ? <ScopedNotification theme="light" className="slds-m-bottom--small">{t("integrations.no-integration-yet", "No integration created yet!")}</ScopedNotification> : null}

        <Accordion>
            {integrationSftp.map((integration, i) => {
                Log.Debug("Integration:", integration);
                const lastRequestTime = integration.lastExport ? moment(integration.lastExport).format('DD.MM.YYYY HH:mm:ss') : t("common.time.never", "never");
                return <AccordionPanel expanded={false} key={i} id={i}
                                       summary={`${integration.url}`}
                                       panelContentActions={`${integration.lastExportStatus.slice(0, 150)} @ ${lastRequestTime}`}
                >
                    <Formik
                        initialValues={{
                            ...integration,
                            port: integration.port.toString(),
                            cron: cronToMoment(integration.cron)
                        }}
                        initialStatus={{
                            readOnly: true,
                            canEdit: canEdit,
                        }}
                        validate={(values, props) => {
                            let errors = {};
                            console.log(values.cron)
                            if (!(/^\d+$/.test(values.port))) {
                                errors.port = t("integrations.sftp.validation.port-not-a-number", "Port need to be a number. (22 is mostly used default.)");
                            }
                            return errors;
                        }}
                        onSubmit={(values, actions) => {
                            Log.Debug("SftpApiTab.submit", values);
                            return updateIntegrationMutation({
                                variables: {
                                    id: integration.id,
                                    input: {
                                        url: values.url,
                                        port: parseInt(values.port),
                                        filePath: values.filePath,
                                        groupByTags: values.groupByTags,
                                        username: values.username,
                                        password: values.password,
                                        cron: momentToCron(values.cron),
                                        inputFilterId: values.inputFilter?.id || 0, // set 0 to remove, null would be just ignored
                                    }
                                }
                            }).then((res) => {
                                notify.success(t("integrations.sftp.notify.updated-integration", "Updated FTP integration."));
                            }).catch((err) => {
                                notify.error(t("integrations.sftp.notify.update-integration-failed", "Failed to update FTP integration."), err);
                            });
                        }}
                    >{(formik) => {
                        return <Form>
                            <SldsInputField name={"url"} label={t("integrations.sftp.target-host-ip", "Target Host/IP Address")} placeholder={"example.com / 127.0.0.1"} required={true} autoFocus={true}/>
                            <SldsInputField name={"port"} validate={(val) => {
                                val.match(RegExp('^[0-9]+$'))
                            }} label={"Port"} required={true} autoFocus={true}/>
                            <SldsInputField name={"filePath"} label={t("integrations.sftp.file-path", "Path on Server")} placeholder={"uploads/metering/"} required={true} autoFocus={true}/>
                            <SldsCheckboxField name={"groupByTags"} label={t("integrations.sftp.group-by-tags", "Group export by Tags (Separate Files per Tag)")} autoFocus={true}/>
                            <SldsInputField name={"username"} label={t("integrations.sftp.username", "Username")} placeholder={"ftp-user"} required={true} autoFocus={true}/>
                            <SldsInputField name={"password"} type={"password"} label={t("integrations.sftp.password", "Password")} placeholder={"*****"} required={true} autoFocus={true}/>
                            <IntegrationFilterLookupField name={"inputFilter"} orgId={orgId} autoFocus={true}/>
                            <SldsFormElementCompound label={t("integrations.sftp.time-to-run-utc", "Time to run (UTC)")}>
                                <Field component={ReactTimePicker} label={t("integrations.sftp.time-to-run-utc", "Time to run (UTC)")} name="cron"/>
                            </SldsFormElementCompound>
                            <FormActions>
                                <SubmitButtonField/>
                                <CancelButtonField/>
                                <Button variant={"destructive"} iconName={"delete"} onClick={() => {
                                    if (window.confirm(t("integrations.confirm.delete-integration", "Delete integration?"))) {
                                        deleteIntegrationMutation({variables: {id: integration.id}})
                                            .then(() => {
                                                notify.success(t("integrations.sftp.notify.deleted-integration", "Deleted FTP integration."));
                                            })
                                            .catch((err) => {
                                                notify.error(t("integrations.sftp.notify.delete-integration-failed", "Failed to delete FTP integration"), err);
                                            });
                                    }
                                }}>{t("common.button.delete", "Delete")}</Button>
                            </FormActions>
                        </Form>;
                    }}</Formik>

                    <div className="slds-text-heading--medium slds-m-top--medium slds-m-bottom--xx-small">{t("integrations.sftp.last-export-success", "Last Export / Last Success")}</div>
                    <DescriptionList>
                        <DescriptionListEntry label={"Last successful Export"} description={integration.lastSuccessfulExport ? integration.lastSuccessfulExport : t("common.time.never", "never")}/>
                        <DescriptionListEntry label={"Last Export"} description={integration.lastExport ? integration.lastExport : t("common.time.never", "never")}/>
                    </DescriptionList>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.sftp.last-export-result", "Last Export Result")}</div>
                    <textarea className="slds-size_1-of-1 slds-text-font_monospace" readOnly rows={6} defaultValue={integration.lastExportStatus}/>
                </AccordionPanel>
            })}
        </Accordion>

        {canEdit ?
            <Button iconName={"add"} iconCategory={"utility"} onClick={() => setModalOpen(true)}>{t("integrations.add-integration-button", "Add Integration")}</Button>
            : null}


        <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} header={t("integrations.sftp.create-itegration-heading", "Create SFTP Integration")}>
            <Formik
                initialValues={{
                    port: "22",
                    cron: cronToMoment("10 2 * * *")
                }}
                validate={(values, props) => {
                    let errors = {};
                    if (!(/^\d+$/.test(values.port))) {
                        errors.port = t("integrations.sftp.validation.port-not-a-number", "Port need to be a number. (22 is mostly used default.)");
                    }
                    return errors;
                }}
                onSubmit={(values, actions) => {
                    Log.Debug("SftpApiTab.submit", values);
                    createIntegrationMutation({
                        variables: {
                            input: {
                                url: values.url,
                                port: parseInt(values.port),
                                filePath: values.filePath,
                                groupByTags: values.groupByTags,
                                username: values.username,
                                password: values.password,
                                cron: momentToCron(values.cron)
                            }
                        }
                    }).then(() => {
                        notify.success(t("integrations.sftp.notify.created-integration", "Created FTP integration."));
                        setModalOpen(false);
                    }).catch((err) => {
                        notify.error(t("integrations.sftp.notify.create-integration-failed", "Failed to create FTP integration."), err);
                    }).finally(() => {
                        actions.setSubmitting(false);
                    });

                }}
            >
                <Form>
                    <SldsInputField name={"url"} label={t("integrations.sftp.target-host-ip", "Target Host/IP Address")} placeholder={"example.com or 127.0.0.1"} required={true} autoFocus={true}/>
                    <SldsInputField name={"port"} label={"Port"} required={true} autoFocus={true}/>
                    <SldsInputField name={"filePath"} label={t("integrations.sftp.file-path", "Path on Server")} placeholder={"uploads/lobaro/"} required={true} autoFocus={true}/>
                    <SldsCheckboxField name={"groupByTags"} label={t("integrations.sftp.group-by-tags", "Group export by Tags (Separate Files per Tag)")} autoFocus={true}/>
                    <SldsInputField name={"username"} label={t("integrations.sftp.username", "Username")} placeholder={"ftp-user"} required={true} autoFocus={true}/>
                    <SldsInputField name={"password"} type={"password"} label={t("integrations.sftp.password", "Password")} placeholder={"*****"} required={true} autoFocus={true}/>
                    <SldsFormElementCompound label="Time to run (UTC)">
                        <Field component={ReactTimePicker} label="Time to run (UTC)" name="cron"/>
                    </SldsFormElementCompound>
                    <FormActions>
                        <SubmitButtonField/>
                        <CancelButtonField onClick={() => setModalOpen(false)}/>
                    </FormActions>
                </Form>

            </Formik>
        </Modal>

    </div>

}

// function cronToTimeString(cron) {
//     let parts = cron.split(" ")
//     let time = parts[1].length == 1 ? "0" + parts[1] : parts[1]
//     time = time + ":"
//     time = parts[0].length == 1 ? time + "0" + parts[0] : time + parts[0]
//     return time
// }

function cronToMoment(cron) {
    let parts = cron.split(" ")
    let hour = parts[1].length == 1 ? "0" + parts[1] : parts[1]
    let minute = parts[0].length == 1 ? "0" + parts[0] : parts[0]
    return moment("2010-10-20 " + hour + ":" + minute, "YYYY-MM-DD HH:mm");
}

function momentToCron(moment) {
    let hour = parseInt(moment.format("H"))
    let minute = parseInt(moment.format("m"))
    return minute + " " + hour + " * * *"
}