import gql from "graphql-tag";
import React, {useContext} from "react";
import * as log from "../../../common/log";
import {QuerySafe} from "../../../common/graphql";
import Page from "../page";
import {Link} from "react-router-dom";
import EditAlarmConfigForm from "./editAlarmConfigForm";
import {Button} from "@salesforce/design-system-react";
import {refetchAlarmConfigs} from "./alarmConfigTable";
import {AppContext} from "../../appPage";
import {useAuthContext} from "../../../common/context/authContext";

const QUERY_ALARM_CONFIG = gql`
    query alarmConfig($alarmConfigId: ID!) {
        alarmConfig(id: $alarmConfigId) {
            id
            name
            nameNoAlarm
            email
            triggerJs
        }
    }
`;

const QUERY_TEST_ALARM_CONDITION = gql`
    query testAlarmCondition($input: String!, $triggerJs: String!) {
        alarm: testAlarmCondition(input: $input, triggerJs: $triggerJs) {
            id
            reason
            active
        }
    }
`;

const MUTATE_UPDATE_ALARM_CONFIG = gql`
    mutation updateAlarmConfig($alarmConfigId: ID!, $alarmConfig: AlarmConfigInput!) {
        updateAlarmConfig(id: $alarmConfigId, input: $alarmConfig) {
            id
            name
            nameNoAlarm
            email
            triggerJs
        }
    }
`;
const MUTATE_DELETE_ALARM_CONFIG = gql`
    mutation deleteAlarmConfig($alarmConfigId: ID!) {
        deleteAlarmConfig(id: $alarmConfigId) {
            id
            name
            triggerJs
        }
    }
`;


export default function AlarmConfigDetailPage(props) {
    const {match, history} = props;

    const app = useContext(AppContext);
    const auth = useAuthContext();

    // ${match.params.id}
    log.Debug("AlarmConfigDetailPage loading alarmConfigId:", match.params.id);
    return <QuerySafe query={QUERY_ALARM_CONFIG} fetchPolicy='cache-first' variables={{alarmConfigId: match.params.id}}>
        {(result) => {
            const {client} = result;
            const {alarmConfig} = result.data;
            log.Debug("AlarmConfigDetailPage result = ", result);
            //log.Debug("DeviceDetailPage match = ", match);
            return <Page title={`${alarmConfig.name}`}
                         trail={[<Link to=".." key={1}>Alarm</Link>, <Link to={`.`} key={2}>{alarmConfig.name}</Link>]}
            >
                <div className="slds-m-around--medium">
                    <EditAlarmConfigForm
                        alarmConfig={alarmConfig}
                        canEdit={auth.hasRole("admin")}
                        onUpdate={(values, actions) => {
                            log.Debug("on update");
                            return client.mutate({
                                mutation: MUTATE_UPDATE_ALARM_CONFIG,
                                variables: {
                                    alarmConfigId: alarmConfig.id,
                                    alarmConfig: {
                                        name: values.name,
                                        nameNoAlarm: values.nameNoAlarm,
                                        email: values.email,
                                        triggerJs: values.triggerJs,
                                    }
                                }
                            })
                        }}
                        onTestCondition={(values) => {
                            log.Debug("onTestCondition with", values);
                            return client.query({
                                query: QUERY_TEST_ALARM_CONDITION,
                                fetchPolicy: "network-only",
                                variables: {
                                    input: values.testInput,
                                    triggerJs: values.triggerJs,
                                }
                            })
                        }}
                    />

                    <Button type={"button"} variant={"destructive"} className="slds-m-top--medium" onClick={() => {
                        if (!window.confirm("Delete Alarm?")) {
                            return;
                        }

                        client.mutate({
                            mutation: MUTATE_DELETE_ALARM_CONFIG,
                            variables: {
                                alarmConfigId: alarmConfig.id,
                            },
                            refetchQueries: [refetchAlarmConfigs(app.id)],
                        }).then(() => {
                            history.push("..")
                        })

                    }}>Delete
                        Alarm</Button>

                </div>

            </Page>
        }}
    </QuerySafe>
}