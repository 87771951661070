import _ from "lodash"
import {useCallback, useEffect, useRef} from "react";



export function useDebounce(cb, delay, options ) {
    // source see ford04 answer on: https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
    const inputsRef = useRef({cb, delay}); // mutable ref like with useThrottle
    useEffect(() => { inputsRef.current = { cb, delay }; }); //also track cur. delay
    return useCallback(
        _.debounce((...args) => {
                // Debounce is an async callback. Cancel it, if in the meanwhile
                // (1) component has been unmounted (see isMounted in snippet)
                // (2) delay has changed
                if (inputsRef.current.delay === delay)
                    inputsRef.current.cb(...args);
            }, delay,
        ),
        [delay, _.debounce]
    );
}