import React, {useContext, useState} from "react";
import {Field, Formik} from "formik";
import {Log} from "../../common/log";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {AppContext} from "../../app/appPage";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import {NotifyUser} from "../../common/userNotification";
import {
    CancelButtonField,
    FormActions, SldsButton,
    SldsFormElementRow,
    SldsInputField, SldsTextarea,
    SubmitButtonField
} from "../../common/ui/form/formElements";
import {Modal} from "../../common/slds";
import {Form} from "../../common/ui/form/formik";
import moment from "moment";
import {useAuthContext} from "../../common/context/authContext";
import Button from "../../common/slds/buttons/button";
import ScopedNotification from "../../common/slds/scopedNotifications/scopedNotification";
import DescriptionList, {DescriptionListEntry} from "../../common/slds/descriptionList/descriptionList";
import {useT} from "../../common/i18n";
import IntegrationFilterLookupField from "../../components/integration/integrationFilterLookupField";

const MUTATION_CREATE_INTEGRATION_MQTT = gql`
    mutation ($orgId: ID!, $input: IntegrationMqttInput) {
        integrationMqtt: createIntegrationMqtt(orgId: $orgId, input: $input){
            id
        }
    }
`;

const MUTATION_UPDATE_INTEGRATION_MQTT = gql`
    mutation ($id: ID!, $input: IntegrationMqttInput!) {
        integrationMqtt: updateIntegrationMqtt(id: $id input: $input){
            id
        }
    }
`;

const MUTATION_DELETE_INTEGRATION_MQTT = gql`
    mutation ($id: ID!) {
        integrationMqtt: deleteIntegrationMqtt(id: $id){
            id
        }
    }
`;

const QUERY_INTEGRATION_MQTT = gql`
    query ($orgId: ID!) {
        integrationMqtt: integrationMqttList(orgId: $orgId){
            id
            broker
            clientId
            password
            tlsClientCert
            tlsClientKey
            tlsBrokerCert
            topic
            username
            lastPublish {
                broker
                clientId
                error
                payload
                qos
                time
                topic
            }
            inputFilter {
                id
                name
            }

        }
    }
`;

export const MqttApiTab = (props) => {
    const t = useT();
    const app = useContext(AppContext);
    const auth = useAuthContext();
    const [modalOpen, setModalOpen] = useState(false);
    const orgId = auth.organisationId();
    const canEdit = auth.hasRole("admin", "org-admin");
    const appId = app?.id;
    const integrationMqttResult = useQuery(QUERY_INTEGRATION_MQTT, {
        variables: {
            "appId": appId,
            "orgId": orgId,
        }
    });

    const [createIntegrationMutation] = useMutation(MUTATION_CREATE_INTEGRATION_MQTT, {
        variables: {
            "appId": appId,
            "orgId": orgId,
        },
        refetchQueries: [{
            query: QUERY_INTEGRATION_MQTT,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });
    const [updateIntegrationMutation] = useMutation(MUTATION_UPDATE_INTEGRATION_MQTT, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_MQTT,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });
    const [deleteIntegrationMutation] = useMutation(MUTATION_DELETE_INTEGRATION_MQTT, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_MQTT,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });

    const loading = useGraphqlLoadingComponent(integrationMqttResult);
    if (loading) {
        return loading;
    }

    Log.Debug("integrationMqttResult", integrationMqttResult);
    const {integrationMqtt} = integrationMqttResult.data;

    return <div className="slds-m-horizontal--x-small">
        <p className="slds-m-bottom--medium">{t("integrations.mqtt.explanation", "A MQTT integration sends device data to a remote server via MQTT publish.")}</p>
        {integrationMqtt.length === 0 ? <ScopedNotification theme="light" className="slds-m-bottom--small">{t("integrations.no-integration-yet", "No integration created yet!")}</ScopedNotification> : null}
        <Accordion>
            {integrationMqtt.map((integration, i) => {
                const lastRequestTime = integration.lastPublish?.time ? moment(integration.lastPublish?.time).format('DD.MM.YYYY HH:mm:ss') : t("common.time.never", "never");
                return <AccordionPanel expanded={false} key={i} id={i}
                                       summary={`${integration.broker} [${integration.topic}]`}
                                       panelContentActions={`${integration.lastPublish?.error || t("integrations.mqtt.success", "Success")} @ ${lastRequestTime}`}
                >
                    <Formik
                        initialValues={{
                            ...integration
                        }}
                        initialStatus={{
                            readOnly: true,
                            canEdit: canEdit,
                        }}
                        onSubmit={(values, actions) => {
                            Log.Debug("MqttApiTab.submit", values);
                            updateIntegrationMutation({
                                variables: {
                                    id: integration.id,
                                    input: {
                                        broker: values.broker,
                                        clientId: values.clientId,
                                        username: values.username,
                                        password: values.password,
                                        topic: values.topic,
                                        inputFilterId: values.inputFilter?.id || 0, // set 0 to remove, null would be just ignored
                                        tlsClientCert: values.tlsClientCert,
                                        tlsClientKey: values.tlsClientKey,
                                        tlsBrokerCert: values.tlsBrokerCert,
                                    }
                                }
                            }).then(() => {
                            }).catch((err) => {
                                NotifyUser.Error(t("integrations.mqtt.notify.update-failed", "Failed to update MQTT integration."), err);
                            }).finally(() => {
                                actions.setSubmitting(false);
                            });

                        }}>
                        <Form>
                            <IntegrationFilterLookupField name={"inputFilter"} orgId={orgId}/>
                            <SldsInputField name={"broker"} label={"Broker URL"} placeholder={"tcp://example.org:1883"} required={true}/>
                            <SldsInputField name={"clientId"} label={"ClientId"} placeholder={""}/>
                            <SldsInputField name={"username"} label={"Username"} placeholder={""}/>
                            <SldsInputField name={"password"} label={"Password"} placeholder={""} type="password"/>
                            <SldsInputField name={"topic"} label={"Topic"} placeholder={"e.g. data/up"}/>

                            <Field component={SldsTextarea} name="tlsClientCert" placeholder={""} label={"TLS Client Cert"} rows={4} />
                            <Field component={SldsTextarea} name="tlsClientKey" placeholder={""} label={"TLS Client Key"} rows={4} />
                            <Field component={SldsTextarea} name="tlsBrokerCert" placeholder={""} label={"TLS Broker Cert"} rows={4} />


                            <FormActions>
                                <SubmitButtonField/>
                                <CancelButtonField/>
                                <Button variant={"destructive"} iconName={"delete"} onClick={() => {
                                    if (window.confirm(t("integrations.confirm.delete-integration", "Delete integration?"))) {
                                        deleteIntegrationMutation({variables: {id: integration.id}})
                                            .catch((err) => {
                                                NotifyUser.Error(t("integrations.mqtt.notify.delete-failed", "Failed to delete MQTT integration"), err);
                                            });
                                    }
                                }}>{t("common.button.delete", "Delete")}</Button>
                            </FormActions>
                        </Form>

                    </Formik>

                    <div className="slds-text-heading--medium slds-m-top--medium slds-m-bottom--xx-small">{t("integrations.mqtt.last-publish", "Last Publish")}</div>
                    <DescriptionList>
                        <DescriptionListEntry label={t("integrations.mqtt.time", "Time")} description={lastRequestTime}/>
                        <DescriptionListEntry label={"Broker"} description={integration.lastPublish?.broker}/>
                        <DescriptionListEntry label={"ClientId"} description={integration.lastPublish?.clientId}/>
                        <DescriptionListEntry label={"Topic"} description={integration.lastPublish?.topic}/>
                        <DescriptionListEntry label={"QoS"} description={integration.lastPublish?.qos}/>
                        <DescriptionListEntry label={t("integrations.mqtt.error", "Error")} description={integration.lastPublish?.error || t("integrations.mqtt.error-none", "none")}/>
                    </DescriptionList>

                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.mqtt.payload", "Payload")}</div>
                    <textarea className="slds-size_1-of-1 slds-text-font_monospace" readOnly rows={6}>
                        {integration.lastPublish?.payload}
                    </textarea>
                </AccordionPanel>;
            })}
        </Accordion>

        {canEdit ?
            <Button iconName={"add"} iconCategory={"utility"} onClick={() => setModalOpen(true)}>{t("integrations.add-integration-button", "Add Integration")}</Button>
            : null}

        <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} title={t("integrations.mqtt.create-integration-heading", "Create MQTT Integration")}>
            <Formik
                initialValues={{
                    method: "post"
                }}
                onSubmit={(values, actions) => {
                    Log.Debug("MqttApiTab.submit", values);
                    createIntegrationMutation({
                        variables: {
                            input: {
                                broker: values.broker,
                                clientId: values.clientId || "",
                                username: values.username,
                                password: values.password,
                                topic: values.topic,
                            }
                        }
                    }).then(() => {
                        setModalOpen(false);
                    }).catch((err) => {
                        NotifyUser.Error(t("integrations.mqtt.notify.delete-failed", "Failed to delete MQTT integration"), err);
                    }).finally(() => {
                        actions.setSubmitting(false);
                    });

                }}
            >
                <Form>
                    <SldsInputField name={"broker"} label={"Broker URL"} placeholder={"e.g. tcp://example.org:1883"} required={true}/>
                    <SldsInputField name={"clientId"} label={"ClientId"} placeholder={""}/>
                    <SldsInputField name={"username"} label={"Username"} placeholder={""}/>
                    <SldsInputField name={"password"} label={"Password"} placeholder={""} type="password"/>
                    <SldsInputField name={"topic"} label={"Topic"} placeholder={"e.g. data/up"} required={true}/>

                    <FormActions>
                        <SubmitButtonField/>
                        <CancelButtonField onClick={() => setModalOpen(false)}/>
                    </FormActions>
                </Form>
            </Formik>
        </Modal>
    </div>;
};