import React, {useContext, useEffect, useState} from "react";
import UserHomePage from "./userhome/userHomePage";
import AuthPage from "./auth/authPage";
import WmbusApiModule from "./wmbusapi/wmbusApiModule";
import OrganisationPage from "./organisation/organisationPage";
import ConfigurationPage from "./configuration/configurationPage";
import HardwareActivationPage from "./hardwareActivation/hardwareActivationPage";
import {Route, Switch} from "react-router-dom";
import {Header} from "./header/header";
import TopNavigation from "./header/topNavigation";
import {useAuthContext} from "./common/context/authContext";
import TopNavigationLink from "./header/topNavigationLink";
import TopNavigationDropdown from "./header/topNavigationDropdown";
import TopNavigationDropdownLink from "./header/topNavigationDropdownLink";
import HeaderOrganisationLogo from "./header/HeaderOrganisationLogo";
import {FrontendConfigContext} from "./common/context/frontendConfigContext";
import {DeviceTypesPage} from "./deviceTypes/deviceTypesPage";
import {DeviceTypesDetailPage} from "./deviceTypes/deviceTypesDetailPage";
import {useT} from "./common/i18n";


const NonAppModule = (props) => {
    const auth = useAuthContext();
    let t = useT();

    // Reload the page when auth state changes, e.g. switching the org
    let [reload, setReload] = useState(0);
    useEffect(() => {
        auth.changeListeners.push((auth) => {
            // Leads to endless loop on login
            //setReload(Math.random());
        });
    });

    const frontendConfig = useContext(FrontendConfigContext);

    //set platform name
    if (frontendConfig?.brandingConfig?.platformName) {
        let pageTitle = frontendConfig.brandingConfig.platformName;
        if(pageTitle !== 'IoT Platform') {
            pageTitle += ' - IoT Platform';
        }
        document.title = pageTitle;
    }
    //set favicon
    if (frontendConfig?.brandingConfig?.favicon && document.getElementById("favicon")?.href != frontendConfig?.brandingConfig?.favicon) {
        document.getElementById("favicon").href = frontendConfig.brandingConfig.favicon
    }

    return <>
             <Header logo={<HeaderOrganisationLogo/>} logoLinkTo={'/'}/>
            <TopNavigation
                moduleName={ frontendConfig?.brandingConfig?.platformName ? frontendConfig?.brandingConfig.platformName : ""}
                secondary={<>
                    {auth.isLoggedIn() && <>
                        <TopNavigationLink to={"/organisation/devices"} label={t("nav.devices", "Devices")}/>
                        <TopNavigationLink to={"/organisation/deviceData"} label={t("nav.data", "Data")}/>
                        <TopNavigationLink to={"/organisation/integrations"} label={t("nav.integrations", "Integrations")}/>
                        <TopNavigationLink to={"/deviceTypes"} label={t("nav.device-types", "Device Types")}/>
                    </>}
                    {(auth.hasRole("org-admin") || auth.hasRole("admin")) && <>
                        <TopNavigationLink to={"/organisation/config"} label={t("nav.organisation", "Organisation")}/>
                    </>}
                    {auth.hasRole("admin") && <>
                        <TopNavigationLink to={"/configuration"} label={t("nav.configuration", "Configuration")}/>
                    </>}
                    <ToolsTopNavigationDropdown/>
                </>}
            />
            <Switch>
                <Route exact path={"/"} component={UserHomePage}/>
                <Route path={"/auth"} component={AuthPage}/>
                <Route path={"/wmbus"} component={WmbusApiModule}/>
                <Route path={"/organisation"} component={OrganisationPage}/>
                <Route exact path={"/deviceTypes"} component={DeviceTypesPage}/>
                <Route path={"/deviceTypes/:id"} component={DeviceTypesDetailPage}/>
                <Route path={"/configuration"} component={ConfigurationPage}/>
                {frontendConfig?.hardwareActivationEnabled ? <Route path={"/hardwareActivation/org/:orgId?"} component={HardwareActivationPage}/> : null}
                {frontendConfig?.hardwareActivationEnabled ? <Route path={"/hardwareActivation/:activationCode?"} component={HardwareActivationPage}/> : null}
                <Route render={() => <div>
                    <div>Not Found</div>
                </div>}/>
            </Switch>
    </>;
};


const ToolsTopNavigationDropdown = (props) => {
    const auth = useAuthContext();
    let t = useT();
    const frontendConfig = useContext(FrontendConfigContext);

    const topNavigationDropdownLinks = []
    if (auth.isLoggedIn() || frontendConfig?.wmbusParserApiPublic){
        topNavigationDropdownLinks.push(
            <TopNavigationDropdownLink to={"/wmbus"} label={t("nav.wmbus-parser-api", "wMbus Parser API")}/>
        )
    }
    if (frontendConfig?.hardwareActivationEnabled){
        topNavigationDropdownLinks.push(
            <TopNavigationDropdownLink to={"/hardwareActivation/org"} label={t("nav.hardware-activation", "Hardware Activation")}/>
        )
    }

    if (topNavigationDropdownLinks.length === 0) {
        return null
    }
    return <TopNavigationDropdown label={t("nav.tools", "Tools")}>
        {topNavigationDropdownLinks.map((elem, i) => {return <div key={i}>{elem}</div>})}
    </TopNavigationDropdown>
}

export default NonAppModule;