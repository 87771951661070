import * as React from "react";
import Page from "../../common/ui/page";
import {Link, useRouteMatch} from "react-router-dom";
import {Button} from "@salesforce/design-system-react";
import {useAuthContext} from "../../common/context/authContext";
import Url from "../../common/url"
import AppsTable from "./appsTable";


const AppsPage = () => {
    const auth = useAuthContext();
    const match = useRouteMatch();
    const actions = <div>
        <Link to={Url.join(match.url, "/new")}>
            {auth.hasRole("admin") ?
                <Button iconCategory={"utility"} iconName={"add"} iconPosition={"left"}
                        label={"New Application"}/> : null}
        </Link>
    </div>;

    return <Page
        trail={[<Link to={match.url} key={1}>Applications</Link>]}
        title={"Applications"}
        actions={actions}
    >
        <AppsTable/>
    </Page>
};

export default AppsPage;