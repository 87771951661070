import * as PropTypes from "prop-types";
import React from "react";
import * as log from "../../../common/log";
import {SldsFormElement, SldsInput} from "../../../common/ui/form/formElements";
import {Field} from "formik";
import {VisualPicker, Radio} from "@salesforce/design-system-react";


// Set value prop to the device type to be rendered
const DeviceTypeRadio = (props) => {
   // Log.Debug("DeviceTypeRadio.props", props)
    const {field, deviceType} = props;
    //const {value} = field;
    return <span className="slds-radio">
        {/*`value: ${deviceType.id} field.value: ${field.value} field.name: ${field.name}`*/}
        <input type="radio" id={deviceType.id} {...field} value={deviceType.id} checked={deviceType.id == field.value}/>
                    <label className="slds-radio__label" htmlFor={deviceType.id}>
                        <span className="slds-radio_faux"/>
                        <span className="slds-form-element__label">{deviceType.displayName}</span>
                        <img src={`/static/img/device-types/${deviceType.name}.jpg`} onError={(event) => event.target.src = `/static/img/blank_10x10.png`}/>
                    </label>
    </span>
};


DeviceTypeRadio.propTypes = {
   // id: PropTypes.string.isRequired,
    deviceType: PropTypes.shape({
        id: PropTypes.number.isRequired,
        displayName: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired
};

export default class DeviceTypeSelector extends React.Component {

    static propTypes = {
        deviceTypes: PropTypes.array.isRequired,
        label: PropTypes.string,
        form: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        onInlineEdit: PropTypes.func
    };

    render() {
        const {deviceTypes, label, form, readOnly, onInlineEdit} = this.props;
        const props = this.props;
        const filterDeviceType = form.values['filterDeviceType'] || '';
        const error = props.form.errors["deviceTypeId"] || false;

        //Log.Debug("DeviceTypeSelector props", this.props);
        //Log.Debug("DeviceTypeSelector form.values", this.props.form.values);

        if (readOnly) {
            return <Field component={SldsInput} readOnly={true} onInlineEdit={onInlineEdit} label={label} name="deviceType.displayName" placeholder="Device type"/>
        }

        return <SldsFormElement error={!!error} {...props}>
            {label ? <legend className="slds-form-element__legend slds-form-element__label">{label}</legend> : null}
            {!readOnly ? <Field component={SldsInput} name="filterDeviceType" placeholder="Search device type" id="filterDeviceType"/> : null}
            {error && !readOnly ? <div className="slds-form-element__help">{error}</div> : null}
            {!readOnly ? <div className="slds-form-element__control slds-m-top--x-small">

                <div className="slds-grid slds-wrap">
                    {
                        deviceTypes
                            .filter((t) => t.displayName.toLowerCase().includes(filterDeviceType.toLowerCase()))
                            .map((t) => {
                                return <div key={t.id} className="slds-col slds-size_1-of-6">
                                    <Field component={DeviceTypeRadio} name="deviceType.id" placeholder="Filter device types" deviceType={t} /*value={t} id={t.id} deviceType={t}*//>
                                </div>
                            })
                    }
                </div>
            </div> : null}


        </SldsFormElement>
    }
}