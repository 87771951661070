import {useQuery} from "@apollo/react-hooks";
import {Log} from "../log";
import {download} from "../download";
import {compileHandlebars} from "../cachedHandlebarsCompiler";
import {useNotificationContext} from "../../notifications/notificationContext";
import {useT} from "../i18n";

export const toCsvLine = (strings) => strings
        .map(s => "\"" + s.toString().replace("\"", "\"\"") + "\"")
        .join(";")
    + "\n";


const csvPageSize = 10000
export const useCsvExport = (query, options) => {
    const t = useT()
    const notify = useNotificationContext();
    const result = useQuery(query, {
        skip: true,
        fetchPolicy: "network-only",
        variables: options.variables
    });

    return {
        export: (tableConfig) => {
            Log.Debug("CSV Export with Table config", tableConfig);
            let csvData = "";
            const exportedCols = tableConfig.cols.filter(col => col.csvFormat?.length > 0);
            csvData += toCsvLine(exportedCols.map(col => col.heading || ""));

            Log.Debug("Result", result);
            let finished = false

            async function doit() {
                for (let i = 0; !finished; i++){
                    let refetchResult = await result.refetch({page: {offset: i * csvPageSize, limit: csvPageSize}})
                    let y = 0
                    if (refetchResult.errors){
                        notify.error(refetchResult.errors)
                        return
                    }
                    options.dataExtractor(refetchResult).forEach(item => {
                        //Log.Debug("item", item);
                        csvData += toCsvLine(exportedCols.map(col => compileHandlebars(col.csvFormat || col.cell?.format, item)));
                        y++
                    });
                    finished = y < csvPageSize
                }
                download(csvData, "export.csv", "text/csv");
                notify.success(t("csv.success","downloaded data"))
            }
            doit().catch(err => notify.error(t("csv.failed","failed to download data"), err))

        }
    };
};
