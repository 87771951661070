import {usePagination} from "../../common/hooks/usePagination";
import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import {Link} from "react-router-dom";
import moment from "moment";
import React from "react";
import {useAuthContext} from "../../common/context/authContext";
import {UserRolePills} from "../user/UserRolesField";

export const OrganisationUserList = (props) => {
    const auth = useAuthContext()
    const page = usePagination();

    const organisationId = props.organisationId

    const usersListResult = useQuery(gql`
        query($page: PaginationInputType, $orgId: ID ) {
            getUserList(page: $page, sort: {field: "createdAt", direction: "DESC"}, orgId: $orgId) {
                id
                login
                organisation {
                    id
                }
                createdAt
                roles
                email
                lastLogin
            }
        }
    `, {
        variables: {
            orgId: organisationId
        }
    });

    let isAdmin = auth.hasRole("admin");
    let items = usersListResult.data && usersListResult.data.getUserList
    if (!isAdmin && usersListResult.data) {
        console.log(usersListResult.data.getUserList)
        items = usersListResult.data.getUserList.filter(u => u.roles == null || !u.roles.includes("admin"))
    }

    return  <GenericDataTable id={"user-table"} items={items} tableConfigDefault={{}} gqlResult={usersListResult}
                              page={page}>
        <DataTableColumn label="ID" property={"id"}
                         render={(props) => isAdmin ? <Link to={`/configuration/users/${props.item.id}`}>{props.item.id}</Link> : props.item.id}
        />
        <DataTableColumn label="Login" property="login" title={"login"}
                         render={(props) =>     isAdmin ? <Link to={`/configuration/users/${props.item.id}`}>{props.item.login}</Link> : props.item.login}/>

        <DataTableColumn label="E-Mail" property="email" title={"email"}/>
        <DataTableColumn label="Roles" property="roles" title={"roles"} render={ (props) =>  {return <UserRolePills roles={props.item.roles}/>}} />
        <DataTableColumn label={"Created"} property={"createdAt"} title={"createdAt"} render={
            (props) => {
                return <div
                    className="slds-cell-wrap">{moment(props.item.createdAt).fromNow()} ({moment(props.item.createdAt).format('DD.MM.YYYY HH:mm:ss')})</div>}
        }/>
        <DataTableColumn label={"Last Login"} property={"lastLogin"} title={"lastLogin"}  render={(props) => {
            return <div
                className="slds-cell-wrap">{lastLoginCell(props.item?.lastLogin)}</div>;
        }}/>
    </GenericDataTable>

};

function lastLoginCell(lastLogin){
    if (lastLogin) {
        return `${moment(lastLogin).fromNow()} ${moment(lastLogin).format('DD.MM.YYYY HH:mm:ss')}`
    } else {
        return "never"
    }

}