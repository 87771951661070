import PropTypes from "prop-types";
import React, {useState} from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import {CustomPropTypes} from "../../propTypes/customPropTypes";
import ReactTooltip from "react-tooltip";
import {Icon} from "../icons/icon";

function AddToBody(props) {
    let bodyNode = document.getElementsByTagName("BODY")[0];
    return ReactDOM.createPortal(
        props.children,
        bodyNode
    );
}

Tooltip.propTypes = {
    children: CustomPropTypes.children,
};

// Wrap arround element that shows the tooptip on hover
export default function Tooltip(props) {
    const {nubbin, top, left, visible, position, content, scrollable} = props;

    const [isVisible, setIsVisible] = useState(visible);

    return <span style={{position: "relative", whiteSpace: "nowrap"}}
                 onMouseEnter={() => visible === undefined && setIsVisible(true)}
                 onMouseLeave={() => visible === undefined && setIsVisible(false)}
    >
        {props.children}
        {isVisible && <div className={classNames("slds-popover slds-popover_tooltip", {
            "slds-rise-from-ground": isVisible,
            [`slds-nubbin_${nubbin}`]: nubbin,
        })}
                           role="tooltip" id="help"
                           style={{position: position, top: top, left: left, overflow: "initial"}}
        >
            <div data-for="tooltip" className={classNames("slds-popover__body", {"slds-popover__body_scrollable": scrollable})}>{typeof content == "function" ? content() : content}</div>
            <ReactTooltip id="tooltip" effect={"solid"}/>
        </div>}
    </span>;
};

Tooltip.defaultProps = {
    position: "absolute",
    nubbin: "bottom-left",
    top: "0px",
    left: "0px",
};

Tooltip.propTypes = {
    content: PropTypes.any.isRequired, // String, Component or Render Function
    top: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
    nubbin: PropTypes.oneOf(["bottom-left"]),
    visible: PropTypes.bool,
    scrollable: PropTypes.bool,
    position: PropTypes.oneOf(["absolute", "relative"])
};