import React from "react";
import * as PropTypes from "prop-types";
import {Route} from "react-router-dom";


export const RouterContext = React.createContext();

const ContextRoute = ({context, component, render, ...props}) => {
    const Component = component;
    let ctx = context || RouterContext;

    // console.log("Context = ", context)

    return (
        <Route {...props} render={(routeProps) => {
            return <ctx.Provider value={routeProps}>
                {Component ? <Component {...routeProps}/> : render(routeProps)}
            </ctx.Provider>
        }}>
        </Route>
    );
};

export default ContextRoute;

ContextRoute.propTypes = {
    component: PropTypes.any,
    render: PropTypes.func,
    context: PropTypes.any
};