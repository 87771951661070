import PropTypes from "prop-types";
import React, {useContext} from "react";
import {Icon} from "../../../../common/slds/icons/icon";
import {SldsInputField} from "../../../../common/ui/form/formElements";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {useGraphqlLoadingComponent} from "../../../../common/graphql";
import {AppContext} from "../../../appPage";
import {Log} from "../../../../common/log";
import {SldsPill} from "../../../../common/ui/sldsPill";
import {RouterContext} from "../../../../common/RouterContext";
import _ from "lodash"

const QUERRY_ALARMS = gql`
    query getAlarms($appId: ID) {
        alarms(appId: $appId) {
            id
            active
            reason
            triggeredLast
            alarmConfig {
                id
                name
            }
        }
    }
`;


export const AlarmPillsWidget = (props) => {
    const {settings} = props;
    const {labelGood, labelBad, alarmConfigId} = settings;
    const app = useContext(AppContext);
    const {history} = useContext(RouterContext);

    const alarmsResult = useQuery(QUERRY_ALARMS, {
        pollInterval: 5000,
        variables: {appId: app.id}
    });


    const loading = useGraphqlLoadingComponent(alarmsResult);
    if (loading) {
        return loading;
    }

    const alarms = alarmsResult.data.alarms || [];


    Log.Debug("alarmConfigId", alarmConfigId);

    let alarmConfigIds = [];
    // fallback clear error  warning
    let alarmPills = alarms.map(a => {
        console.log("a:",a);
        if (!a.active) {
            return null;
        }
        // already displayed alarm config
        if (_.find(alarmConfigIds, (id) => id === a.alarmConfig.id)) {
            return null
        }

        alarmConfigIds.push(a.alarmConfig.id);
        Log.Debug("alarmConfigIds",alarmConfigIds );
        return <SldsPill
            key={a.id}
            icon={<Icon category="utility" className="" name={"warning"} colorVariant={"error"} title={a.reason}/>}
            label={a.alarmConfig.name}
            title={a.reason}
            error={true}
            value={a}
            //onRemove={() => {}}
            onClick={settings.href ? (v) => {
                Log.Debug("ClickPill", v);
                history.push(settings.href)
            } : undefined}
        >
        </SldsPill>
    }).filter((a) => a != null) || [];

    return <div className="slds-p-vertical--xx-small slds-p-horizontal--x-small">{alarmPills}</div>
};

AlarmPillsWidget.propTypes = {
    settings: PropTypes.object.isRequired
};
export const AlarmPillsWidgetSettings = (props) => {
    const {settings} = props;

    return <React.Fragment>
        <SldsInputField name={"href"} label={"URL"} placeholder={"/<appId>/devices/"}/>
    </React.Fragment>

};

AlarmPillsWidgetSettings.propTypes = {
    settings: PropTypes.object.isRequired,
};

