import React from "react";
import PropTypes from "prop-types";
import {Link, NavLink, useRouteMatch} from "react-router-dom";
import {matchPath, useLocation} from "react-router";
import {classnames} from "../../wrapper/classnames";


export function VerticalNavEntry(props) {
    const {children, to, isActive, exact} = props;
    const match = useRouteMatch();
    const location = useLocation();

    return <li className={classnames("slds-nav-vertical__item",
        {"slds-is-active": isActive ? isActive(match, location) : matchPath(location.pathname, {path: to, exact: exact})}
    )}>
        <Link to={to} exact={exact} className="slds-nav-vertical__action">{children}</Link>
    </li>
}

VerticalNavEntry.propTypes = {
    children: PropTypes.string,
    to: PropTypes.string.isRequired,
    isActive: PropTypes.func,
    exact: PropTypes.bool,
};

export function VerticalNavEntries(props) {
    const {children} = props;
    return <ul>
        {children}
    </ul>
}

VerticalNavEntries.propTypes = {
    //children: PropTypes.arrayOf(PropTypes.elementType(VerticalNavEntry)),
};

export function VerticalNavTitle(props) {
    const {children} = props;

    return <h2 id="entity-header" className="slds-nav-vertical__title">{children}</h2>
}

VerticalNavTitle.propTypes = {
    children: PropTypes.node,
};

export function VerticalNavSection(props) {
    const {children} = props;
    return <div className="slds-nav-vertical__section">
        {children}
    </div>
}

VerticalNavSection.propTypes = {
    // VerticalNavTitle and VerticalNavEntries
    children: PropTypes.node
};

export default function VerticalNav(props) {
    const {children} = props;
    return <nav className="slds-nav-vertical" aria-label="Sub page">
        {children}
    </nav>
}

VerticalNav.propTypes = {
    children: PropTypes.node,
};