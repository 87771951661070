import {useEffect, useState} from "react";
import {useDebounce} from "./useDebounce";

class Sort {
    sortInfo = {}
    setSortInfo = () => {}

    constructor(sortInfo, setSortInfo, reset) {
        this.sortInfo = sortInfo;
        this.setSortInfo = setSortInfo;
        this.resetFunction = reset
    }

    getGraphqlSortInput() {
        if (!this.sortInfo.property) {
            return null;
        }
        return {
            field: this.sortInfo.property || "",
            direction: this.sortInfo.direction || "ASC",
        }
    }

    reset(){
        this.resetFunction()
    }

    isSorted() {
        return !!this.sortInfo.property;
    }
}

export function useSort(initialSort = {},  refetchQuery = null) {
    if (initialSort == null) {
        initialSort = {}
    }
    const [sortInfo, setSortInfo] = useState(initialSort);
    function reset() {
        setSortInfo(initialSort)
    }
    var sort = new Sort(sortInfo, setSortInfo, reset);
    const refetch = useDebounce(refetchQuery, 250)
    useEffect(() => {
            if (refetchQuery) {
                refetch({
                    sort: sort.getGraphqlSortInput(),
                })
            }
        },
        [sortInfo]
    )



    return new Sort(sortInfo, setSortInfo, reset);
}