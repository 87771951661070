import gql from "graphql-tag";

export const MUTATE_CREATE_DEVICE_TYPE = gql`
    mutation deviceType($name: String!, $input:DeviceTypeInput) {
        createDeviceType(name: $name, input: $input) {
            id
            name
            displayName
            parserCode
            parserEnabled
            configProperties
            organisationId
            private
        }
    }
`;

export const MUTATE_UPDATE_DEVICE_TYPE = gql`
    mutation ($id: ID!, $input: DeviceTypeInput!) {
        updateDeviceType(id: $id, input: $input) {
            id
            name
            displayName
            parserCode
            parserEnabled
            configProperties
            organisationId
            private
        }
    }
`;

export const QUERY_DEVICE_TYPE = gql`
    query deviceType($id: ID!) {
        deviceType(id: $id) {
            id
            name
            displayName
            description
            parserCode
            parserEnabled
            configProperties
            deviceTableConfigRaw
            dataTableConfigRaw
            deviceTraits
            numDevices
            organisationId
            organisation {
                id
                name
            }
            private
        }
    }
`;

export const QUERY_DEVICE_TYPES = gql`
    query ($page: PaginationInputType, $search: String, $orgId: ID, $onlyWithExistingDevices: Boolean) {
        deviceTypes(page: $page, sort: {field: "id", direction: "DESC"}, search: $search, orgId: $orgId, onlyWithExistingDevices: $onlyWithExistingDevices) {
            id
            name
            displayName
            configProperties
            organisationId
            private
        }
    }
`;