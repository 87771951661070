import {Query} from "react-apollo";
import {GraphqlLoadingErrorHandler} from "../../common/graphql";
import {useRouteMatch} from "react-router-dom"
import * as React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import slds from '../../common/slds'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from 'moment'
import 'moment-timezone'
import _ from 'underscore'
import ErrorBoundary from "../../common/ui/errorBoundary";
import LightboxImage from '../../common/ui/lightboxImage';
import * as log from "../../common/log";

// TODO: Query parsedData based on time!
const query = {
    getDevice: gql`
query getDevice($devId: ID!, $dataSince: String!, $limit: Int!) {
  device(id: $devId) {
    id
    addr
    description
    location {
      lat,
      lon
    }
    config {
      geoJson: jsonValue(path:"map.geoJson")
      sensorInfo: jsonValue(path:"sensorInfo")
      image: stringValue(path:"image")
    }
    lastParsedDataByType(type:"data") {
      createdAt
    }
    parsedData(page: {limit: $limit, offset: 0}, filter: {field: "time", op: "gte", value: $dataSince}) {
      type
      data {
        time: stringValue(path: "time")
        sensorId: stringValue(path: "id")
        temperature: floatValue(path: "temperature")
      }
    }
  }
}
`
};
// https://www.google.de/maps/place/50.964567,6.986082
// https://www.google.de/maps/place/${device.location.lat},${device.location.lon}

function temperatureChartOptions(data, sensorInfo) {

//    data = data.filter(d => d.data.sensorId == "ffa8f9c11701");

    log.Debug("temperatureChartOptions.data: ", data);

    let charts = _.groupBy(data, (d) => {
        return d.data.sensorId;
    });

    log.Debug("charts: ", charts);

    let series = [];
    _.keys(charts).forEach(k => {
        const info = sensorInfo.find(s => s.id === k) || {};
        series.push({
            type: 'line',
            marker: {
                //enabled: true,
                enabledThreshold: 4,
            },
            tooltip: {
                xDateFormat: '%e.%m.%Y %H:%M:%S'
                //dateTimeLabelFormats: {
                //    day: "%A, %b %e, %Y %H:%M:%S"
                //}
            },
            name: info.name ? info.name : k,
            // TODO: Sort data
            data: charts[k].map(d => {
                return [moment(d.data.time).valueOf(), d.data.temperature];
            })
        });
    });


    log.Debug("series: ", series)
    /*
        let series = sensorInfo.map(i => {
            return {
                type: 'line',
                name: i.label,
                data: [...data["sensor" + i.idx]].map((v) => [v[0], v[1]]).filter((x) => x[1] !== 0).reverse(),
            }

        });
    */

    return {
        chart: {
            zoomType: 'x',
        },
        title: {
            text: '',
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: 'Temperatur [°C]'
            }
        },
        legend: {
            enabled: true
        },
        credits: {enabled: false},
        plotOptions: {},

        series: series
    }
}

const TemperatureChart = (props) => {
    return <HighchartsReact
        highcharts={Highcharts}
        options={temperatureChartOptions(props.data, props.sensorInfo)}
        style={{width: "auto"}}
    />
};
TemperatureChart.propTypes = {
    data: PropTypes.array.isRequired,
    sensorInfo: PropTypes.array.isRequired
};

// Temperature for one Chain
const TemperatureTable = (props) => {
    log.Debug("props.data: ", props.data);
    const {sensorInfo} = props;

    let temperatures = props.data.map(d => {
        let info = sensorInfo.find(i => i.id === d.data.sensorId) || {};
        return {
            ...d.data,
            id: d.data.sensorId, // id is needed for react table
            time: moment(d.data.time).tz("Europe/Berlin").fromNow(), //.format("DD.MM.YYYY HH:mm:ss z"),
            name: info ? info.name : "",
        };
    });

    temperatures = _.uniq(temperatures, function (p) {
        return p.sensorId;
    });

    temperatures = _.sortBy(temperatures, function (p) {
        return p.name;
    });


    return <slds.DataTable items={temperatures} fixedLayout={true}>
        <slds.DataTableColumn label="Id" property="sensorId" width="7rem"/>
        <slds.DataTableColumn label="Name" property="name" width="6rem"/>
        <slds.DataTableColumn label="Temp [*C]" property="temperature" width="6rem"/>
        <slds.DataTableColumn label="Date" property="time"/>
    </slds.DataTable>
};
TemperatureTable.propTypes = {
    data: PropTypes.array.isRequired,
    sensorInfo: PropTypes.array.isRequired
};

function tryParseJson(json, def) {
    try {
        return JSON.parse(json)
    } catch (err) {
        log.Error(err)
        return def
    }
}

class DeviceDetailsComponent extends React.Component {

    render() {
        let props = this.props;
        let device = props.data.device;
        let data = device.parsedData;
        let config = device.config;
        log.Debug("Config: ", config);
        let sensorInfo = tryParseJson(config.sensorInfo, []);


        return <div>
            <div className="slds-m-left--small slds-m-bottom--small">
                <div className="slds-text-heading_large">{props.data.device.description}</div>
                <div className="">Zuletzt
                    aktualisiert: {moment(props.data.device.lastParsedDataByType.createdAt).format("DD.MM.YYYY HH:mm:ss")}</div>
            </div>
            <div className="slds-grid slds-wrap">
                <div className="slds-size_1-of-1 slds-large-size_2-of-4">
                    <div className="slds-m-left--small">
                        <LightboxImage src={config.image} className=""/>
                        <a href={`https://www.google.de/maps/place/${device.location.lon},${device.location.lat}`}
                           target="_blank">Show in Google Maps</a></div>
                    {sensorInfo.length === 0 ? <div>No SensorInfo defined</div> : null}
                    <div className="slds-m-top--medium">
                        <TemperatureTable data={data} sensorInfo={sensorInfo}/>
                    </div>
                </div>
                <div className="slds-size_1-of-1 slds-large-size_2-of-4">
                    <ErrorBoundary>
                        <TemperatureChart data={data} sensorInfo={sensorInfo}/>
                    </ErrorBoundary>
                </div>
            </div>

            {/*device details: {JSON.stringify(props.device)}*/}
        </div>
    }
}

const DeviceDetails = (props) => {
    const match = useRouteMatch();

    let devId = props.devId || match.params.devId;

    return <Query query={query.getDevice}
        //fetchPolicy={'network-only'}
                  variables={{
                      devId: devId,
                      limit: 5000,
                      dataSince: moment().subtract(7, 'days')
                  }}>
        {(result) => {

            return <GraphqlLoadingErrorHandler result={result}>
                {() => {
                    return <DeviceDetailsComponent data={result.data} {...props}/>
                }}
            </GraphqlLoadingErrorHandler>
        }}
    </Query>
};
DeviceDetails.propTypes = {
    devId: PropTypes.string.isRequired,
};


export default DeviceDetails