import "./polyfills"
import React from "react";
import Settings from "@salesforce/design-system-react/module/components/settings";
import ReactDOM from "react-dom";
import {platformConfig} from "./common/platformConfig";
import * as log from "./common/log";
import Platform from "./platform";
import {Log} from "./common/log";
import {backendUrl} from "./common/helper";
import './style'


if (platformConfig.devMode) {
    log.Info("DevMode. Auth disabled!");
}

Log.Info("Backend URL:", backendUrl());

Settings.setAppElement("#root");
ReactDOM.render(<Platform/>, document.getElementById('root'));
