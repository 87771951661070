import Page from "../../../common/ui/page";
import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {RouterContext} from "../../../common/RouterContext";
import {Grid} from "./grid";
import {Log} from "../../../common/log";
import {ButtonGroup} from "@salesforce/design-system-react";
import {CancelButtonField, FormActions, IconButtonStateful, Option, SldsInputField, SldsSelectField, SubmitButtonField} from "../../../common/ui/form/formElements";
import {generateUUID} from "../../../common/uuid";
import {HasPermission} from "../../../common/ui/permissions";
import {Modal} from "../../../common/slds";
import {Formik} from "formik";
import {Form} from "../../../common/ui/form/formik";
import {widgetRegistry} from "./widgetFrame";
import _ from "underscore";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATE_UPDATE_DASHBOARD, QUERY_DASHBOARD} from "./queries";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import Button from "../../../common/slds/buttons/button";


export const DashboardPage = (props) => {

    const {match} = useContext(RouterContext);

    const {dashboardId} = match.params;

    const [fake, setFake] = useState(false);
    const update = () => {
        setFake(!fake)
    };
    const dasbhoardsResult = useQuery(QUERY_DASHBOARD, {variables: {dashboardId: dashboardId}});
    const [updateDashboard] = useMutation(MUTATE_UPDATE_DASHBOARD, {variables: {dashboardId: dashboardId}});
    const [edit, setEdit] = useState(false);
    const [newWidgetOpen, setNewWidgetOpen] = useState(false);

    const loading = useGraphqlLoadingComponent(dasbhoardsResult);
    if (loading) {
        return loading;
    }
    let dashboard = dasbhoardsResult.data.dashboard;
    dashboard = {
        ...dashboard,
        layout: JSON.parse(dashboard.layoutRaw || "[]"),
        widgets: JSON.parse(dashboard.widgetsRaw || "[]")
    };

    Log.Debug("DashboardPage.dashboard", dashboard);

    const addWidget = () => {
        setNewWidgetOpen(true);

    };

    const toggleEdit = () => {
        setEdit(!edit);
    };

    return <Page title={`${dashboard.name}`}
                 trail={[<Link to=".." key={1}>Dashboards</Link>, <Link to="." key={2}>{dashboard.name}</Link>]}
                 actions={<ButtonGroup>
                     {edit ? <Button iconName={"add"} onClick={addWidget}>Add Widget</Button> : null}
                     <HasPermission role={"admin"}> <IconButtonStateful active={edit} iconName={"settings"} onClick={toggleEdit}/></HasPermission>
                 </ButtonGroup>}
    >
        <Modal isOpen={newWidgetOpen} onRequestClose={() => setNewWidgetOpen(false)}>
            <Formik
                initialValues={{}}
                onSubmit={((values, actions) => {
                    if (!dashboard.widgets) {
                        dashboard.widgets = [];
                    }
                    dashboard.widgets.push({
                        id: generateUUID(),
                        type: values.type,
                        settings: {
                            name: values.name,
                        },
                        col: 0,
                        row: 0,
                        width: 3,
                        height: 3,
                    });
                    updateDashboard({
                        variables: {
                            input: {
                                widgets: JSON.stringify(dashboard.widgets)
                            }
                        }
                    }).then(() => {
                        setNewWidgetOpen(false);
                        actions.setSubmitting(false);
                    }, (e) => {
                        Log.Error("Failed to update dashboard:", e);
                        actions.setSubmitting(false);
                    });

                })}
            >{() => {
                return <Form>
                    <SldsInputField name={"name"} label={"Name"} placeholder={"Widget Title"} required/>
                    <SldsSelectField name={"type"} required>
                        <Option value={""}>Please select</Option>
                        {_.keys(widgetRegistry).map((k) => {
                            const w = widgetRegistry[k];
                            return <Option value={k} key={k}>{w.displayName}</Option>
                        })}
                        <Option value={"unknown"}>Unknown</Option>
                    </SldsSelectField>
                    <FormActions>
                        <SubmitButtonField>Submit</SubmitButtonField>
                        <CancelButtonField onClick={() => setNewWidgetOpen(false)}/>
                    </FormActions>
                </Form>
            }}</Formik>
        </Modal>
        <Grid dashboard={dashboard}
              readOnly={!edit}
              onLayoutChange={(layout) => {
                  Log.Debug("onLayoutChange", layout);

                  layout.forEach((l) => {
                      (dashboard.widgets).forEach((w) => {
                          if (w.id === l.i) {
                              w.width = l.w;
                              w.height = l.h;
                              w.col = l.x;
                              w.row = l.y;
                          }
                      });
                  });

                  dashboard.layout = layout;
                  updateDashboard({
                      variables: {
                          input: {
                              widgets: JSON.stringify(dashboard.widgets),
                              //layout: JSON.stringify(dashboard.layout),
                          }
                      }
                  });
              }}
              onDashboardChanged={(d) => {
                  updateDashboard({
                      variables: {
                          input: {
                              widgets: JSON.stringify(d.widgets),
                             //layout: JSON.stringify(d.layout),
                          }
                      }
                  });
                  //update();
              }}

        />
    </Page>
};


DashboardPage.propTypes = {
    dashboard: PropTypes.object,
};