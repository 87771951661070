import DataTableCell from "./cell";
import React from "react";
import PropTypes from "prop-types";

/**
 * @deprecated: Use render prop of DataTableColumn
 */
const CustomDataTableCell = ({children, ...props}) => {
    const {render} = props;
    return <DataTableCell {...props}>
        {render(props)}
    </DataTableCell>;
};
CustomDataTableCell.displayName = DataTableCell.displayName;
CustomDataTableCell.propTypes = {
    render: PropTypes.func.isRequired,
};

export default CustomDataTableCell