import normalize from 'normalize-path'
import _ from 'underscore'
import urlJoin from "url-join"

// Join joins any number of path elements into a single path, adding a
// separating slash if necessary. The result is Cleaned; in particular,
// all empty strings are ignored.
function join(...elem) {
    if (!_.isArray(elem)) {
        return "";
    }
    return urlJoin(elem)

    for (let i = 0; i < elem.length; i++) {
        if (elem[i] !== "") {
            return normalize((elem.splice(i).join("/")))
        }
    }
}

// Has no auto import in intelliJ
/*
export default class Url {
    static join = join;
}
*/

const Url = {
    join,
};
export default Url