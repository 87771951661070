import {useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import PropTypes from "prop-types";
import React from "react";
import {useFormikContext} from "formik";

export function DeviceAppLookupField(props) {
    let {name, label, readOnly, orgId} = props;
    const formik = useFormikContext();
    if (!label) {
        label = `App${!orgId ? " (assign organisation to edit app)" : ""}`;
    }
    if (readOnly === undefined) {
        readOnly = formik.status.readOnly || !orgId;
    }


    const appsResult = useQuery(gql`
        query ($search: String, $orgId: ID) {
            appInstances(page: {limit: 10, offset: 0}, orgId: $orgId, search: $search) {
                id
                name
            }
        }
    `);

    return <SingleLookupField
        readOnly={readOnly}
        name={name} label={label}
        titleExtractor={it => it?.name}
        subtitleExtractor={it => it?.id}
        loadSuggestions={keyword => appsResult.refetch({search: keyword, orgId: orgId})
            .then(result => result.data.appInstances)}
    />;
}

DeviceAppLookupField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    orgId: PropTypes.string.isRequired,
};