import * as React from "react";
import {useState} from "react";
import Page from "../app/pages/page";
import ParserForm from "../common/ui/parserForm";
import {Link, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {Log} from "../common/log";
import Url from "../common/url";
import UpdateDeviceTypeDialog from "./updateDeviceTypeDialog";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "./queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import {Tab, TabNavigation} from "../common/ui/tabNavigation";
import {NotifyUser} from "../common/userNotification";
import DeviceTableConfigTab from "./deviceTableConfigTab";
import DataTableConfigTab from "./dataTableConfigTab";
import {YesNoDialog} from "../common/slds/modals/YesNoDialog";
import gql from "graphql-tag";
import Button from "../common/slds/buttons/button";
import {useAuthContext} from "../common/context/authContext";
import {useT} from "../common/i18n";

export function DEFAULT_DEVICE_TABLE_CONFIG(t) {
    return {
        cols: [
            {
                heading: t("device-type.device-table-config.heading.date", "Date"),
                cell: {
                    format: "{{date createdAt}}",
                }
            },
            {
                heading: t("device-type.device-table-config.heading.device", "Device"),
                cell: {
                    format: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
                    href: "#/organisation/devices/{{device.id}}/device-data",
                }
            },
            {
                heading: t("device-type.device-table-config.heading.address", "Address"),
                cell: {
                    format: "{{device.addr}}",
                    href: "#/organisation/devices/{{device.id}}/device-data",
                }
            },
        ]
    }
}

export const DEFAULT_DATA_TABLE_CONFIG = {
    cols: []
};

export function DeviceTypesDetailPage(props) {
    const t = useT();
    const auth = useAuthContext();
    const canEdit = auth.hasRole("admin");
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const deletePending = useState(false);

    Log.Debug("DeviceTypesDetailPage.props", props);
    Log.Debug("DeviceTypesDetailPage.match", match);
    const deviceTypeId = match.params.id; // TODO: better -> match.params.deviceTypeId and use in Tabs!!!

    const deviceTypeResult = useQuery(QUERY_DEVICE_TYPE, {
        variables: {
            id: deviceTypeId
        }
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: match.params.id},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]
    });

    const [deleteDeviceType] = useMutation(gql`
        mutation($id: ID!) {
            deleteDeviceType(id: $id)
        }`, {
        variables: {id: match.params.id}
    });

    function restoreDefaultDeviceTableConfig() {
        return updateDeviceType({
            variables: {
                id: deviceType.id,
                input: {
                    deviceTableConfig: JSON.stringify(DEFAULT_DEVICE_TABLE_CONFIG(t)),
                }
            }
        }).catch((err) => {
            NotifyUser.Error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
        });
    }

    function restoreDefaultDataTableConfig(t) {
        return updateDeviceType({
            variables: {
                id: deviceTypeId,
                input: {
                    deviceTableConfig: JSON.stringify(DEFAULT_DATA_TABLE_CONFIG),
                }
            }
        }).catch((err) => {
            NotifyUser.Error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
        });
    }

    if (location.pathname === match.url) {
        history.push(Url.join(match.url, "overview"));
    }

    const loadingHW = useGraphqlLoadingComponent(deviceTypeResult);
    if (loadingHW) {
        return loadingHW;
    }
    const deviceType = deviceTypeResult.data.deviceType;

    Log.Debug("deviceType", deviceType);
    return <Page
        trail={[<Link to={"/deviceTypes"} key={1}>Device Types</Link>,
            <Link to={`/deviceTypes/${deviceType.id}`} key={2}>{deviceType.displayName}</Link>]}
        title={`${deviceType.displayName}`}
        info={<>{deviceType.name} • {deviceType.numDevices} Devices</>}
        actions={<> {canEdit && deviceType.numDevices === 0 &&
        <Button iconName={"delete"} className="slds-button--destructive" onClick={() => deletePending[1](true)}>Delete
            DeviceType</Button>}
        </>}
    >
        <TabNavigation tabs={[
            <Tab key={"overview"} to={Url.join(match.url, 'overview')}>Overview</Tab>,
            <Tab key={"parser"} to={Url.join(match.url, 'parser')}>Parser</Tab>,
            <Tab key={"device-table-config"} to={Url.join(match.url, 'deviceTableConfig')}>Device Table Config</Tab>,
            <Tab key={"data-table-config"} to={Url.join(match.url, 'dataTableConfig')}>Data Table Config</Tab>,
        ]}>
            <div className="slds-m-around--small">
                <Switch>
                    <Route exact path={Url.join(match.path, 'overview')}>
                        <UpdateDeviceTypeDialog deviceType={deviceType}/>
                    </Route>
                    <Route exact path={Url.join(match.path, 'parser')}>
                        <ParserForm defaultParserCode={"// Using hardcoded GoLang parser"}
                                    readOnly={!canEdit}
                                    parserCode={deviceType.parserCode}
                                    inputCode={"{}"}
                                    parserSelection={deviceType.parserEnabled ? "custom" : "default"}
                                    saveParser={(values, actions) => {
                                        const {parserCode, parserSelection} = values;

                                        const parserEnabled = (parserSelection !== "default");

                                        updateDeviceType({
                                            variables: {
                                                id: deviceType.id,
                                                input: {
                                                    parserCode: parserCode,
                                                    parserEnabled: parserEnabled,
                                                }
                                            }
                                        }).catch((err) => {
                                            NotifyUser.Error("Failed to save parser", err);
                                        }).finally(() => {
                                            actions.setSubmitting(false);
                                        });
                                    }}
                        />
                    </Route>
                    <Route exact path={Url.join(match.path, 'deviceTableConfig')}>
                        <DeviceTableConfigTab deviceTypeId={deviceTypeId}/>
                    </Route>
                    <Route exact path={Url.join(match.path, 'dataTableConfig')}>
                        <DataTableConfigTab deviceTypeId={deviceTypeId}/>
                    </Route>
                </Switch>
            </div>
            <YesNoDialog open={deletePending} title={"Really delete"}
                         text={"Do you really want to delete the device type?"} onYes={() => {
                deleteDeviceType().then(history.push("/deviceTypes"))
            }}/>
        </TabNavigation>
    </Page>
}