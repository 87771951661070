import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {QUERY_APPLICATIONS} from "../configuration/queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import {Link} from "react-router-dom";
import {AccordionPanel} from "../common/ui/accordion";
import {useAuthContext} from "../common/context/authContext";
import {useT} from "../common/i18n";

// Shows a list of all applications available to the user and allows to select one
// TODO: This should be moved into the TopNavigation App Launcher in future
const AppOverview = (props) => {
    const t = useT();
    const auth = useAuthContext();
    const orgId = auth.organisationId();

    const result = useQuery(QUERY_APPLICATIONS, {
        variables: {
            orgId: orgId,
        },
        fetchPolicy: 'no-cache', // Might change after re-login then we must not use the cache
    });
    const {data} = result;

    const loading = useGraphqlLoadingComponent(result);
    if (loading) {
        return loading;
    }

    const {appInstances} = data;

    if (appInstances?.length === 0) {
        return null;
    }

    return <AccordionPanel
        id={"apps"}
        summary={t("app-overview.iot-applications", "IoT Applications")} expanded={true}
        panelContentActions={<div>
            <div className="slds-input-has-icon slds-input-has-icon_right">
                <input type="text" className="slds-input" autoFocus={true} onChange={e => {
                    result.refetch({search: e.target.value});
                }}/>
                <span
                    className="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                    <svg className="slds-icon slds-icon slds-icon_x-small slds-icon-text-default" aria-hidden="true">
                        <use href="/assets/icons/utility-sprite/svg/symbols.svg#search"/>
                    </svg>
                </span>
            </div>
        </div>
        }>

        <div className="slds-m-around--medium">
            {appInstances && appInstances.length > 0 && <>
                <ul className="slds-grid slds-wrap slds-grid_pull-padded-xx-small">
                    {appInstances.map((app, i) => <li key={i}
                                                      className="slds-size--1-of-1 slds-medium-size_1-of-3 slds-large-size--1-of-4 slds-p-horizontal--xx-small slds-m-bottom--x-small">
                        <article className="slds-box slds-box--x-small">
                            <div className="slds-tile__detail slds-p-bottom--large">
                                <p className="slds-truncate">{app.appId}</p>
                                <p className="slds-text-heading_medium">
                                    <Link to={'/app/' + app.id + '/devices/'} target="_blank" key={1}>{app.name}</Link>
                                </p>
                                <p className="slds-truncate"
                                   title={app.organisation && app.organisation.name || "[NO ORG]"}>
                                    {app.organisation && app.organisation.name || "[NO ORG]"}
                                </p>
                            </div>
                        </article>
                    </li>)
                    }
                </ul>
            </>
            }
        </div>
    </AccordionPanel>
        ;
};

export default AppOverview;