import React from "react";
import {Link} from "react-router-dom";
import {useAuthContext} from "../common/context/authContext";
import {useT} from "../common/i18n";

const DevicesOverview = (props) => {
    const t = useT();
    const auth = useAuthContext();
    const entries = [
        {
            "name": t("device-overview.my-devices", "My Devices"),
            "link": "/organisation/devices",
            "description": t("device-overview.my-devices-description", "My organisation's hardware devices")
        },
        {
            "name": t("device-overview.my-data", "My Data"),
            "link": "/organisation/deviceData",
            "description": t("device-overview.my-data-description", "The data of my organisation's devices")
        }
    ];
    if(!auth.isLoggedIn()) {
        return <></>;
    }
    return <div className="slds-m-around--medium">
        <ul className="slds-grid slds-wrap slds-grid_pull-padded-xx-small">
            {entries.map((tool, i) => <li key={i}
                                        className="slds-size--1-of-1 slds-medium-size_1-of-3 slds-large-size--1-of-4 slds-p-horizontal--xx-small slds-m-bottom--x-small">
                <article className="slds-box slds-box--x-small">
                    <div className="slds-tile__detail slds-p-bottom--large">
                        <p className="slds-truncate">{tool.name}</p>
                        <p className="slds-text-heading_medium">
                            <Link to={tool.link}>{tool.name}</Link>
                        </p>
                        <p className="slds-truncate">
                            {tool.description}
                        </p>
                    </div>
                </article>
            </li>)
            }
        </ul>
    </div>;
};

export default DevicesOverview;