import * as React from "react";
import * as PropTypes from "prop-types";
import AceEditor from "react-ace";
import 'brace/mode/java';
import 'brace/theme/tomorrow';
import 'brace/ext/language_tools';


export default class CodeEditor extends React.Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        minLines: PropTypes.number,
        maxLines: PropTypes.number,
        width: PropTypes.string,
        readOnly: PropTypes.bool,
    };

    constructor(props) {
        super(props);

    }

    blur(e, editor) {
        const {defaultValue} = this.props;

        if (!editor.session.getValue()) {
            editor.session.setValue(defaultValue);
        }
    }

    onLoad(editor) {
        const {defaultValue} = this.props;

        if (!editor.session.getValue()) {
            editor.session.setValue(defaultValue);
        }
    }

    render() {
        let {id, defaultValue, value, ...props} = this.props;

        //Log.Debug("value: ", value)

        return <AceEditor
            mode="javascript"
            theme="tomorrow"
            //onChange={onChange}
            name="id"
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            tabSize={2}
            editorProps={{$blockScrolling: true}}
            onBlur={(e, editor) => this.blur(e, editor)}
            onLoad={(editor) => this.onLoad(editor)}
            //maxLines={5}
            value={value}

            {...props}
        />
    }
}