// Actually all the info needed upfront to create a device
import {HasPermission} from "../../../common/ui/permissions";
import Page from "../page";
import {Link} from "react-router-dom";
import React from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import {QuerySafe} from "../../../common/graphql";
import NewAlarmConfigForm from "./newAlarmConfigForm";
import {refetchAlarmConfigs} from "./alarmConfigTable";

const queryDeviceTypes = gql`
query deviceTypes {
  deviceTypes {
    id
    name
    displayName
  }
}
`;


export default function NewAlarmConfigPage(props) {
    return <HasPermission role={"admin"} showError={true}>
        <Page title={"Create New Alarm Configuration"}
              trail={[<Link to=".." key={1}>Alarm</Link>, <Link to={`.`} key={2}>New</Link>]}
        >
            <QuerySafe query={queryDeviceTypes}>
                {(result) => {
                    return <NewAlarmConfigForm result={result} onCreateAlarmConfig={(alarmConfig, actions) => {
                        const newAlarmConfig = {
                            appId: alarmConfig.appId,
                            name: alarmConfig.name,
                            triggerJs: alarmConfig.triggerJs,
                        };
                        const mut = result.client.mutate({
                            mutation: gql`
mutation createAlarmConfig($input: AlarmConfigInput!) {
  alarmConfig: createAlarmConfig(input: $input) {
    id
    appId
    name
    triggerJs
  }
}`,
                            variables: {input: newAlarmConfig},
                            refetchQueries: [refetchAlarmConfigs(newAlarmConfig.appId)],
                        });

                        mut.then(() => {
                            props.history.push("..");
                        });

                        return mut
                    }
                    }/>
                }}
            </QuerySafe>
        </Page>
    </HasPermission>
}

NewAlarmConfigPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    })
}