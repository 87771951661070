import React from "react"
import * as slds from '@salesforce/design-system-react';
import PropTypes from "prop-types"

/*
The Top Navigation gives access to the module browser
as well as provides module specific navigation

Modules are separate react applications for a specific usecae
example modules are: app (IoT applications), wmbusapi, configuration (for the whole dashboard)
 */
const TopNavigation = (props) => {
    const {moduleName, secondary, tertiary} = props;

    /* TODO: Deleteme? Or use to display App name later!
    const loading = useGraphqlLoadingComponent(appResult);
    if (loading) {
        return loading;
    }

    let appName = 'Lobaro Dashboard';

    const app = useContext(AppContext);
    Log.Debug("AppContext:", app);
    const appResult = useQuery(QUERY_APPLICATION, {
        skip: app.id === 0,
        variables: {
            appId: app.id,
        }
    });

    let appInstance;
    if (appResult.data) {
        appInstance = appResult.data.appInstance;
        appName = appInstance.name;
    }

    let logoUrl = "/static/img/logos/lobaro.svg"; // Default
    if (appInstance && appInstance.logo !== "default") {
        logoUrl = backendUrl() + "/data/" + appInstance.logo;
    }
    */

    return <slds.GlobalNavigationBar>
        <slds.GlobalNavigationBarRegion region="primary">
            <div className="slds-context-bar__app-name slds-align-middle">{moduleName}</div>
        </slds.GlobalNavigationBarRegion>
        <slds.GlobalNavigationBarRegion region="secondary" navigation>
            {secondary ? secondary : null}
            {/* <slds.GlobalNavigationBarLink active label="Home" id="home-link" href={"/"}/>
            <slds.GlobalNavigationBarLink label="Configuration" href={"/configuration"}/>*/}
        </slds.GlobalNavigationBarRegion>
        <slds.GlobalNavigationBarRegion region="tertiary">
            {tertiary ? tertiary : null}
            {/* Does not look nice
            <LoginButton/>

            <a href="/">
                <div className="slds-global-header__logo" style={{backgroundImage: `url(${logoUrl})`}}/>
            </a>*/}
        </slds.GlobalNavigationBarRegion>
    </slds.GlobalNavigationBar>
};

TopNavigation.propTypes = {
    moduleName: PropTypes.string.isRequired,
    secondary: PropTypes.any, // List of: TopNavigationLink, more to come ...
    tertiary: PropTypes.any, // List of: TopNavigationLink, more to come ...
};

export default TopNavigation