import React, {useState} from "react";
import PropTypes from "prop-types";
import {SldsFormElement, SldsFormElementLabel} from "../form/formElements";
import classNames from 'classnames';
import _ from "underscore";
import {Log} from "../../log";
import {useFormikContext} from "formik";

/**
 * Lookup for multiple values. If you look for single item lookup check SingleLookup
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Lookup = (props) => {
    const {id, label, placeholder, onLookup, iconExtractor, valueExtractor, titleExtractor, subtitleExtractor, loadSuggestions, onEnterCallback} = props;
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [notFound, setNotFound] = useState(false);

    const formik = useFormikContext();
    const readOnly = formik?.form?.status?.readOnly || props.readOnly;

    const updateSuggestions = _.throttle((value, formik) => {
        Promise.resolve(loadSuggestions(value, formik)).then((suggestions) => {
            Log.Debug("Lookup.updateSuggestions", suggestions);
            setSuggestions(suggestions);
            setNotFound(suggestions.length === 0);
        });

    }, 300, {leading: false});

    const onTypeSearch = (e, formik) => {
        updateSuggestions(e.target.value, formik);
        setValue(e.target.value);
    };

    return <SldsFormElement canEdit={true} readOnly={false} {...props}>
        {label ? <SldsFormElementLabel htmlFor={id}>{label}</SldsFormElementLabel> : null}

        <div className="slds-form-element__control">
            {!readOnly && <div className="slds-combobox_container">
                <div
                    className={classNames("slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click", {"slds-is-open": open})}
                    aria-expanded={open}
                    aria-haspopup="listbox" role="combobox"
                >
                    <div
                        className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right"
                        role="none">
                        <input type="text" className="slds-input slds-combobox__input"
                               id="combobox-id-1"
                               aria-autocomplete="list" aria-controls="listbox-id-1"
                               autoComplete="off"
                               role="textbox"
                               placeholder={placeholder} value={value}
                               onChange={(e) => onTypeSearch(e, formik)}
                               onClick={(e) => {
                                   onTypeSearch(e, formik);
                                   setOpen(true);
                               }}
                               onKeyDown={(e) => {
                                 if(onEnterCallback && e.key === 'Enter') {
                                    onEnterCallback(e);
                                    setOpen(false);
                                    setValue("");
                                    setSuggestions([]);
                                    setNotFound(false);
                                    e.preventDefault();
                                 }
                               }
                               }
                               onBlur={() => setTimeout(() => setOpen(false), 100)}
                        />
                        <span
                            className="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                                                   <svg
                                                       className="slds-icon slds-icon slds-icon_x-small slds-icon-text-default"
                                                       aria-hidden="true">
                                                       <use href="/assets/icons/utility-sprite/svg/symbols.svg#search"/>
                                                   </svg>
                                               </span>
                    </div>
                    <div id="listbox-id-1"
                         className="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid"
                         role="listbox">
                        <ul className="slds-listbox slds-listbox_vertical" role="presentation">
                            {suggestions.length === 0 && notFound && <div>
                                Nothing found
                            </div>}
                            {suggestions.map((option, idx) => <li
                                role="presentation" className="slds-listbox__item"
                                key={idx}
                                onClick={() => {
                                    // We use onMouseDown instead, else a "long" click is not recognized because the UI closes too early.
                                }}
                                onMouseDown={() => {
                                    let value = valueExtractor ? valueExtractor(option) : option;
                                    Log.Debug("Lookup selected value:", value);
                                    onLookup(value);
                                    setOpen(false);
                                    setValue("");
                                    setSuggestions([]);
                                    setNotFound(false);
                                }}

                            >
                                <div
                                    className="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta"
                                    role="option"
                                >
                                                <span className="slds-media__figure slds-listbox__option-icon">
                                                    {iconExtractor && iconExtractor(option)}
                                                    {/*<span className="slds-icon_container slds-icon-standard-account">
                                                        <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                                            <use href="/assets/icons/standard-sprite/svg/symbols.svg#account"/>
                                                        </svg>
                                                    </span>*/}
                                                </span>
                                    <span className="slds-media__body">
                                                    <span
                                                        className="slds-listbox__option-text slds-listbox__option-text_entity">{titleExtractor(option)}</span>
                                                    <span
                                                        className="slds-listbox__option-meta slds-listbox__option-meta_entity">{subtitleExtractor(option)}</span>
                                                </span>
                                </div>
                            </li>)}
                        </ul>
                    </div>
                </div>
            </div>}
        </div>
    </SldsFormElement>;
};

Lookup.defaultProps = {
    placeholder: "Search..."
};

Lookup.propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.string,
    //value: PropTypes.array.isRequired, // TODO Unused?
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    loadSuggestions: PropTypes.func.isRequired,
    titleExtractor: PropTypes.func.isRequired,
    subtitleExtractor: PropTypes.func.isRequired,
    onLookup: PropTypes.func.isRequired,
    iconExtractor: PropTypes.func, // slds-icon slds-icon_small
    valueExtractor: PropTypes.func, // if given, the function is called to get the "value" from the selected option object
    onEnterCallback: PropTypes.func, // function with the event as param, that is called when "enter" is pressed in the editor field.
};

export default Lookup;
