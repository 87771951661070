import React, {useEffect, useState} from "react";
import {AuthContext, getAuthState} from "./authContext";
import {Log} from "../log";
import {useHistory} from "react-router-dom"

const AuthContextProvider = (props) => {
    let auth = getAuthState();
    const history = useHistory();
    let [authState, setAuthState] = useState(auth);
    useEffect(() => {
        auth.changeListeners.push((auth) => {
            Log.Debug("Update AuthContext", auth);
            setAuthState(auth);
            // force reload the page
            // LEADS TO ENDLESS LOOP ON LOGIN!
            //history.replace(history.location);
        });
    });

    return <AuthContext.Provider value={authState}>
        {props.children}
    </AuthContext.Provider>
};

export default AuthContextProvider;