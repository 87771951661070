import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {Option, SldsSelectField} from "../../../../common/ui/form/formElements";
import gql from "graphql-tag";
import {useQuery} from "@apollo/react-hooks";
import {useGraphqlLoadingComponent} from "../../../../common/graphql";
import {AppContext} from "../../../appPage";
import {Log} from "../../../../common/log";
import GenericDataTable from "../../../../common/ui/genericDataTable/genericDataTable";
import {useSort} from "../../../../common/hooks/useSort";
import {useFilter} from "../../../../common/hooks/useFilter";
import {useAppTableConfig} from "../../../../common/ui/genericDataTable/useAppTableConfig";
import {usePagination} from "../../../../common/hooks/usePagination";


const QUERRY_TABLE_DATA = gql`
    query getData($appId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType) {
        alarms(appId: $appId) {
            id
            active
            reason
            triggeredLast
            alarmConfig {
                id
                name
            }
        }
        appInstance(id:$appId) {
            id
            appId
            name
            type
        }
        sensorData(appId: $appId, page: $page, filter: $filter, sort: $sort) {
            id
            createdAt
            dataRaw
            type
            device {
                id
                addr
                name
                description
            }
            datasource {
                id
                name
            }
        }
        devices(appId: $appId, page: $page, filter: $filter, sort: $sort) {
            id
            addr
            name
            description
            propertiesRaw
        }

    }

`;


export const DataTableWidget = (props) => {
    const {settings, widget} = props;
    Log.Debug("DataTableWidget.props", props);
    const {entityKey} = settings;
    const app = useContext(AppContext);
    const sort = useSort();
    const filter = useFilter(false, []);
    const page = usePagination();

    const tableConfigName = `table-widget-${widget.id}`;
    let {loading: loadingTableConfig, tableConfig} = useAppTableConfig(tableConfigName);

    if (tableConfig && tableConfig.defaultFilterString) {
        Log.Debug("DataTableWidget.tableConfig", tableConfig);
        filter.setFilterString(tableConfig.defaultFilterString);
    }
    Log.Debug("DataTableWidget.filter", filter);
    const dataResult = useQuery(QUERRY_TABLE_DATA, {
        fetchPolicy: "cache-and-network",
        skip: !!loadingTableConfig,
        pollInterval: 5000,
        variables: {
            appId: app.id,
            sort: sort.getGraphqlSortInput(),
            filter: filter.getGraphqlFilterInput(),
        }
    });

    const loadingData = useGraphqlLoadingComponent(dataResult);
    if (loadingTableConfig) {
        return loadingTableConfig;
    }

    if (loadingData) {
        return loadingData;
    }

    if (!entityKey) {
        return <div>No data selected</div>
    }
    if (dataResult.data[entityKey] === undefined) {
        return <div>Bad Entity: '{entityKey}'</div>
    }

    let data = dataResult.data[entityKey] || [];

    if (entityKey === "sensorData") {
        data = data.map((d => {
            return {...d, data: JSON.parse(d.dataRaw || "{}")};
        }));
    }
    if (entityKey === "devices") {
        data = data.map((d => {
            return {...d, properties: JSON.parse(d.propertiesRaw || "{}")};
        }));
    }

    Log.Debug("DataTableWidget.data", data);

    return <div className="slds-p-around--xx-small">
        <GenericDataTable
            id={tableConfigName}
            tableConfigName={tableConfigName}
            gqlResult={dataResult}
            items={data}
            tableConfigDefault={tableConfig}
            sort={sort}
            filters={filter}
            page={page}
        />
    </div>;
};

DataTableWidget.propTypes = {
    settings: PropTypes.object.isRequired
};
export const DataTableWidgetSettings = (props) => {
    const {settings, onSubmit, onClose} = props;

    return <React.Fragment>
        <SldsSelectField name={"entityKey"} label={"Entity to be listed"} required={true}>
            <Option value={""}>Please Select</Option>
            <Option value={"alarms"}>Alarms</Option>
            <Option value={"devices"}>Devices</Option>
            <Option value={"sensorData"}>Device Data</Option>
        </SldsSelectField>
    </React.Fragment>
};

DataTableWidgetSettings.propTypes = {
    settings: PropTypes.object.isRequired
};