import * as React from "react";
import {useState} from "react";
import "leaflet/dist/leaflet.css";
import gql from "graphql-tag";

import _ from 'lodash';
import moment from "moment";
import {Button} from "@salesforce/design-system-react";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {useRouteMatch} from "react-router-dom";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../../common/ui/data-table/column";
import CustomDataTableCell from "../../../common/ui/data-table/customCell";
import DeviceMap from "./deviceMap";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import * as log from "../../../common/log";
import {Log} from "../../../common/log";
import Json from "../../../common/ui/json";
import {useAuthContext} from "../../../common/context/authContext";
import {useT} from "../../../common/i18n";
import {NotifyUser} from "../../../common/userNotification";
import Roles from "../../../model/roles";

const QUERY_DEVICE_AND_ALARMS = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            id
            appId
            name
            description
            addr
            propertiesRaw
            location {
                lat
                lon
            }
        }
        alarms(devId: $devId) {
            id
            alarmConfig {
                id
                name
            }
            createdAt
            triggeredLast
            reason
            active
        }
    }
`;

const mutationUpdateDeviceLocation = gql`
    mutation updateDeviceLocation($devId: ID!, $device: DeviceInput!) {
        updateDevice(id: $devId, input: $device) {
            id
            location {
                lat
                lon
            }
        }
    }
`;

const mutationDeleteDevicePropertie = gql`
    mutation deleteDevicePropertie($devId: ID!, $propertieName: String!) {
        deleteDevicePropertie(id: $devId, propertieName: $propertieName) {
            id
            appId
            name
            description
            addr
            propertiesRaw
            location {
                lat
                lon
            }
        }
    }
`;


const mutationDeleteAlarm = gql`
    mutation deleteAlarm($alarmId: ID!) {
        deleteAlarm(id: $alarmId) {
            id
        }
    }
`;

const parseProperties = (device) => {
    try {
        let properties = JSON.parse(device.propertiesRaw);
        properties = _.values(properties);
        Log.Debug("properties", properties);
        return properties;
    } catch (e) {
        log.Error("Failed to parse device propertiesRaw: ", e);
        return [];
    }
};

function isZeroLocation(lat, lon) {
    return lat === 0 && lon === 0;
}

export default function DeviceOverview(props) {
    const t = useT();
    const match = useRouteMatch();
    const devId = match.params.deviceId;

    const [showMap, setShowMap] = useState(false);
    const [deletion, setDeletion] = useState(false);

    const auth = useAuthContext();
    const allowedToDeleteProperties = auth.hasRole(Roles.DEVICE_ADMIN, Roles.ORG_ADMIN, Roles.ADMIN);

    const gqlResult = useQuery(QUERY_DEVICE_AND_ALARMS, {
        pollInterval: 2000,
        fetchPolicy: "network-only",
        variables: {devId: devId},
        onCompleted: (data) => {
            setShowMap(data?.device?.location?.lat, data?.device?.location?.lon);
        }
    });

    const [mutateDeleteAlarm] = useMutation(mutationDeleteAlarm);

    const [mutateUpdateLocation] = useMutation(mutationUpdateDeviceLocation, {
        variables: {
            devId: devId,
        },
    });

    const [mutateDeleteDevicePropertie] = useMutation(mutationDeleteDevicePropertie, {
        variables: {
            devId: devId,
        },
    })

    const loading = useGraphqlLoadingComponent(gqlResult);
    if (loading) {
        return loading;
    }

    //log.Debug("DeviceOverview.result:", gqlResult);
    const {alarms, device} = gqlResult.data;
    const position = [device.location.lat, device.location.lon];
    const properties = parseProperties(device);

    return <div className="slds-p-around--x-small">
        <div className="slds-grid slds-wrap">
            <div className="slds-size_1-of-1">
                {/*Properties: <Json json={properties}/>*/}
                <div className="slds-text-heading--medium slds-m-bottom--small">{t("device.overview.device-properties-heading", "Device Properties")}</div>
                <GenericDataTable id={"device-properties"} hideDetails={true} items={properties} tableConfigDefault={{}}>
                    <DataTableColumn width="1px" label={t("device.overview.properties.name", "Name")} property={"name"}/>
                    <DataTableColumn label={t("device.overview.properties.value", "Value")} property={"value"} render={(props) => {
                        let val = props.item.value;
                        if (typeof (val) === "boolean") {
                            val = val ? t("common.bool.true", "true") : t("common.bool.false", "false");
                        } else if (typeof val === "object") {
                            return <Json json={val}></Json>
                        }
                        return <div className="slds-cell-wrap">{val}</div>;
                    }}/>
                    <DataTableColumn label={t("device.overview.properties.updated", "Updated")} property={"updatedAt"} render={(props) => {
                        return <div
                            className="slds-cell-wrap">{moment(props.item.updatedAt).fromNow()} ({moment(props.item.updatedAt).format('DD.MM.YYYY HH:mm:ss')})</div>;
                    }}/>
                    {deletion ? <DataTableColumn label={t("device.overview.properties.actions", "Actions")} property={"deleteButton"}
                                                 render={(props) => {
                                                     return <Button
                                                         disabled={!deletion}
                                                         assistiveText={t("device.overview.properties.delete-button.assistive", 'Deletes the property from device')}
                                                         iconCategory="utility"
                                                         iconName="delete"
                                                         iconSize="small"
                                                         iconVariant="bare"
                                                         onClick={() => {
                                                             console.log(props.item.id)
                                                             mutateDeleteDevicePropertie({
                                                                 variables: {
                                                                     propertieName: props.item.name,
                                                                 }
                                                             }).then(result => {
                                                                 if (result.error) {
                                                                     log.Info("Failed to delete Propertie: " + error)
                                                                 }
                                                             })
                                                         }}
                                                         variant="icon"
                                                     />
                                                 }}
                    /> : null}
                </GenericDataTable>
                {allowedToDeleteProperties ? <div className="slds-col_bump-left slds-float_right"><br/>
                    <Button
                        className={"slds-button"}
                        iconCategory={"utility"} iconName={"settings"} iconPosition={"left"}
                        label={deletion ? t("device.overview.properties.hide-actions", "Hide Actions") : t("device.overview.properties.edit-properties", "Edit Properties")}
                        onClick={() => {
                            setDeletion(!deletion)
                        }}/>
                    <br/>
                </div> : <div/>}

            </div>
            <div className="slds-size_3-of-3">
                <div className="slds-text-heading--medium slds-m-bottom--small">{t("device.overview.alters.title", "Alerts")}</div>
                <GenericDataTable id={"device-properties"} hideDetails={false} renderDetails={
                    (item) => {
                        return <div className="slds-cell-wrap">{item.reason}</div>;
                    }
                } items={alarms} tableConfigDefault={{}}>
                    <DataTableColumn label={t("device.overview.alerts.name", "Name")} property={"alarmConfig.name"}>
                        <CustomDataTableCell render={(props) => {
                            return <div>{props.item.alarmConfig.name}</div>;
                        }}/>
                    </DataTableColumn>
                    <DataTableColumn label={t("device.overview.alerts.first-alarm", "First Alarm")} property={"createdAt"}>
                        <CustomDataTableCell render={(props) => {
                            return <div
                                className="slds-cell-wrap">{moment(props.item.createdAt).fromNow()} ({moment(props.item.createdAt).format('DD.MM.YYYY HH:mm:ss')})</div>;
                        }}/>
                    </DataTableColumn>
                    <DataTableColumn fixedLayout={false} className="slds-cell-wrap" label={t("device.overview.alerts.last-alarm", "Last Alarm")}
                                     property={"triggeredLast"}>
                        <CustomDataTableCell render={(props) => {
                            return <div
                                className="slds-cell-wrap">{moment(props.item.triggeredLast).fromNow()} ({moment(props.item.triggeredLast).format('DD.MM.YYYY HH:mm:ss')})</div>;
                        }}/>
                    </DataTableColumn>
                    <DataTableColumn fixedLayout={false} className="slds-cell-wrap" label={t("device.overview.alerts.active", "Active")}
                                     property={"active"}>
                        <CustomDataTableCell render={(props) => {
                            return <div className="slds-cell-wrap">{props.item.active ? t("device.overview.alerts.active-true", "true") : t("device.overview.alerts.active-false", "false")}</div>;
                        }}/>
                    </DataTableColumn>
                    <DataTableColumn fixedLayout={false} className="slds-cell-wrap" label={t("device.overview.alerts.actions", "Actions")} property={""}>
                        <CustomDataTableCell render={(props) => {
                            return <Button iconVariant="border" iconSize={"small"} iconCategory="utility"
                                           iconName="delete"
                                           onClick={() => {
                                               if (!window.confirm(t("device.overview.alerts.confirm.delete-alarm", "Delete Alarm?"))) {
                                                   return Promise.resolve();
                                               }
                                               return mutateDeleteAlarm({
                                                   variables: {
                                                       alarmId: props.item.id,
                                                   },
                                               }).then(() => {
                                                   return gqlResult.refetch();
                                               }).catch(err => {
                                                   NotifyUser.Error(t("device.overview.alerts.notify.delete-failed", "Failed to delete alarm"), err);
                                               });
                                           }}
                            />;
                        }}/>
                    </DataTableColumn>
                </GenericDataTable>

            </div>
            <div className="slds-size_1-of-3">
                <div className="slds-text-heading--medium slds-m-bottom--small">{t("device.overview.location.title", "Location")}</div>
                {!!showMap && <div>
                    <DeviceMap position={position} onUpdateLocation={(latLng) => {
                        return mutateUpdateLocation({
                            variables: {
                                device: {
                                    location: {lat: latLng.lat, lon: latLng.lng}
                                }
                            }
                        }).then((result) => {
                            setTimeout(() => {
                                setShowMap(!isZeroLocation(result?.data?.updateDevice?.location?.lat, result?.data?.updateDevice?.location?.lon));
                            }, 0);

                        });
                    }}/>
                    <a href={`https://www.google.de/maps/place/${device.location.lat},${device.location.lon}`}
                       target="_blank">[{device.location.lat.toFixed(6)}, {device.location.lon.toFixed(6)}]</a>
                </div>
                }

                {!showMap && <div><a onClick={() => setShowMap(true)}>Add Location</a></div>}
            </div>
        </div>

        <hr/>
        <div className="slds-float_right">
            <div>Icons made by <a href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those
                Icons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed
                by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0"
                      target="_blank">CC 3.0 BY</a></div>
        </div>
    </div>;
}

DeviceOverview.propTypes = {};