import React, {useState} from "react";
import {Log} from "../common/log";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MUTATE_APPLICATION, QUERY_APPLICATION} from "./queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import NewDevicePage from "./pages/devices/newDevicePage";
import AlarmPage from "./pages/alarm/alarmPage";
import ContextRoute from "../common/RouterContext";
import DeviceDetailPage from "../organisation/devices/device/deviceDetailPage";
import NewAlarmConfigPage from "./pages/alarm/newAlarmConfigPage";
import AlarmConfigDetailPage from "./pages/alarm/alarmConfigDetailPage";
import {SensorDataPage} from "./pages/sensorData/sensorDataPage";
import ParsedDataPage from "./pages/parsedData/parsedDataPage";
import {DatasourcesPage} from "./pages/datasources/datasourcesPage";
import _ from "underscore";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import DeviceListPage from "./pages/devices/deviceListPage";
import {DashboardPage} from "./pages/dashboard/dashboardPage";
import {DashboardsPage} from "./pages/dashboard/dashboardsPage";
import {IntegrationsPage} from "../organisation/integrations/integrationPage";
import TopNavigation from "../header/topNavigation";
import TopNavigationLink from "../header/topNavigationLink";
import ContextBarIconAction from "../header/contextBarIconAction";
import {Modal} from "../common/slds";
import NavigationEditForm from "./navigationEditForm";
import {useAuthContext} from "../common/context/authContext";
import DeviceDetails from "./temperature/deviceDetails";
import DevicesTable from "./temperature/devicesTable";
import Header from "../header/header";
import {platformConfig} from "../common/platformConfig";
import {backendUrl} from "../common/helper";
import HeaderAppLogo from "../header/HeaderAppLogo";


function createNavigation(match) {
    return [
        {
            label: "Devices",
            url: `${match.url}/devices/`,
        },
        {
            label: "Alarm",
            url: `${match.url}/alarm/`,
        },
        {
            label: "Datasources",
            url: `${match.url}/datasources/`,
        },
        {
            label: "Device Data",
            url: `${match.url}/device-data/`,
        },
        {
            label: "Raw Data",
            url: `${match.url}/raw-data/`,
        },
        {
            label: "Dashboards",
            url: `${match.url}/dashboards/`,
            role: "admin",
        },
        {
            label: "Integrations",
            url: `${match.url}/integrations/`,
        },
    ];
}

function buildTopNavigation(navigation, auth) {
    return navigation.map((nav, idx) => {
        if(nav.role) {
            if (!auth.hasRole(nav.role)) {
                return null;
            }
        }
        return <TopNavigationLink key={idx} to={nav.url} label={nav.label}>{nav.role == "admin" ?
            <i style={{color: "#9b9b9b"}}>{nav.label}</i> : nav.label}</TopNavigationLink>;
    }).filter((n) => n !== null);
}

export const AppContext = React.createContext({});

export default function AppPage(props) {
    const match = useRouteMatch();
    const auth = useAuthContext();

    //log.Debug("AppPage.props: ", props);
    //log.Debug("AppPage.location: ", location);
    //log.Debug("AppPage.match: ", match);

    const [mutateNav] = useMutation(MUTATE_APPLICATION, {variables: {appId: match.params.appId}});
    const appResult = useQuery(QUERY_APPLICATION, {variables: {appId: match.params.appId}});
    const loading = useGraphqlLoadingComponent(appResult);
    const [isEditTopNav, setIsEditTopNav] = useState(false);

    if (loading) {
        return <React.Fragment>
            <AppContext.Provider value={{id: match.params.appId, ...platformConfig}}>
                {loading}
            </AppContext.Provider>
        </React.Fragment>;
    }

    const {appInstance} = appResult.data;

    let logoUrl = undefined;
    if (appInstance && appInstance.logo !== "default") {
        logoUrl = backendUrl() + "/data/" + appInstance.logo;
    }

    let nav = JSON.parse(appInstance.navigationRaw || 'null');

    Log.Debug("nav is:", nav);
    let navigation = nav;
    if (_.isEmpty(nav)) {
        navigation = createNavigation(match);
    }
    Log.Debug("navigation is:", navigation);

    const appContext = {...platformConfig, ...appInstance};
    Log.Debug("AppPage.appContext", appContext);

    return <AppContext.Provider value={appContext}>
        <Header logo={<HeaderAppLogo/>} logoLinkTo={'/' /*'/app/' + appContext.id + '/devices/'*/}/>
        <TopNavigation moduleName={appInstance.name}
                       secondary={<React.Fragment>
                           {buildTopNavigation(navigation, auth)}
                       </React.Fragment>}
                       tertiary={<React.Fragment>
                           {auth.hasRole("admin") ? <ContextBarIconAction iconName={"settings"}
                                                                          onClick={() => setIsEditTopNav(!isEditTopNav)}/>
                               : null}
                       </React.Fragment>}
        />
        <Modal isOpen={isEditTopNav} onRequestClose={() => setIsEditTopNav(false)}>
            <NavigationEditForm navigation={navigation}
                                onSubmit={(values, actions) => {
                                    return Promise.resolve(mutateNav({
                                        variables: {
                                            input: {navigation: JSON.stringify(values.navEntries)}
                                        }
                                    })).then(() => {
                                        setIsEditTopNav(false);
                                    });
                                }}
                                onCancel={() => setIsEditTopNav(false)}
            />
        </Modal>
        <div className="slds-container--fluid">
            <div className="slds-grid">
                {/* Vertical nav is deprecated, but m<be we need it again for mobile devices?
                !auth.hasRole("admin") ? null : <div className="slds-p-left--xx-small slds-shrink">
                    <VerticalNavigation navigation={navigation} onSubmit={(values, actions) => {
                        return mutateNav({
                            variables: {
                                input: {navigation: JSON.stringify(values.navEntries)}
                            }
                        });
                    }}/>
                </div>*/}
                <div className="slds-border--left slds-grow">
                    <div className="slds-p-around--xx-small slds-size--1-of-1">
                        <Switch>
                            <ContextRoute exact strict path={`${match.path}/devices/`} component={DeviceListPage}/>
                            <ContextRoute exact strict path={`${match.path}/devices/new/`} component={NewDevicePage}/>
                            <ContextRoute strict path={`${match.path}/devices/:deviceId/`}
                                          component={DeviceDetailPage}/>
                            <ContextRoute exact strict path={`${match.path}/alarm/`} component={AlarmPage}/>
                            <ContextRoute exact strict path={`${match.path}/alarm/new/`}
                                          component={NewAlarmConfigPage}/>
                            <ContextRoute strict path={`${match.path}/alarm/:id/`} component={AlarmConfigDetailPage}/>
                            <ContextRoute exact strict path={`${match.path}/datasources/`} component={DatasourcesPage}/>
                            <ContextRoute exact strict path={`${match.path}/device-data/`} component={ParsedDataPage}/>
                            <ContextRoute exact strict path={`${match.path}/raw-data/`} component={SensorDataPage}/>
                            <ContextRoute exact strict path={`${match.path}/dashboards/`} component={DashboardsPage}/>
                            <ContextRoute exact strict path={`${match.path}/dashboards/:dashboardId/`}
                                          component={DashboardPage}/>
                            <ContextRoute strict path={`${match.path}/integrations/`} component={IntegrationsPage}/>

                            <Route exact path={`${match.path}/temperature/devices/`} component={DevicesTable}/>
                            <Route strict path={`${match.path}/temperature/devices/:devId`} component={DeviceDetails}/>

                            <Route render={(props) => {
                                Log.Debug("Page not found props", props);
                                return <div>Page not found</div>;
                            }}/>
                        </Switch>
                    </div>
                </div>

            </div>
        </div>
    </AppContext.Provider>;
}