import React from "react";
import PropTypes from "prop-types";
import {Link, matchPath, useRouteMatch, useLocation} from "react-router-dom";
import classNames from "classnames"
import {} from "react-router-dom"
import {Log} from "../common/log";

const TopNavigationLink = (props) => {
    let {to, label, active, children} = props;

    const match = useRouteMatch();
    const location = useLocation();

    if (active === undefined) {
        //Log.Debug("TopNavigationLink", location.pathname, to, matchPath(location.pathname, {path: to}));
        active = matchPath(location.pathname, {path: to});
    }

    return <li className={classNames("slds-context-bar__item", {
        "slds-is-active": active,
    })}>
        <Link to={to} className="slds-context-bar__label-action" title={label}>
        <span className="slds-truncate" title={label}>{children || label}</span>
    </Link>
    </li>
};

TopNavigationLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string,
    active: PropTypes.bool, // Can override active
    children: PropTypes.any,
};

export default TopNavigationLink