import React, {useContext} from "react";
import LoginPage from "./loginPage";
import MyAccountPage from "./myAccountPage";
import {Route} from "react-router-dom";
import RegistrationPage from "./registrationPage";
import ChangePasswordPage from "./changePasswordPage";
import RestorePasswordPage from "./RestorePasswordPage";
import {FrontendConfigContext} from "../common/context/frontendConfigContext";

const AuthPage = (props) => {

    let allowRegistration = useContext(FrontendConfigContext)?.allowRegistration
        return <>
            <Route path={"/auth/login"} component={LoginPage}/>
            {allowRegistration ? <Route path={"/auth/register"} component={RegistrationPage}/> : null}
            <Route path={"/auth/restore-password"} component={RestorePasswordPage}/>
            <Route path={"/auth/my-account"} component={MyAccountPage}/>
            <Route path={"/auth/changePassword/:userId"} component={ChangePasswordPage}/>
        </>


};

export default AuthPage;