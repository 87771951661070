import React, {useState} from "react";
import {Log} from "../../log";
import {Icon} from "../../slds/icons/icon";
import Button, {InputButton} from "../../slds/buttons/button";
import {Formik} from "formik";
import {Form} from "../form/formik";
import {CancelButtonField, FormActions, SldsInputField, SldsSelectField, SubmitButtonField} from "../form/formElements";
import SingleLookupField from "../lookup/singleLookupField";
import {useT} from "../../i18n";




export function ColumnTextFilter(props) {
    //todo search/filter from and use it for update of search
    const filterHook = props.filterHook
    const filterProperty = props.property
    const [val, setVal] = useState(null);


    //[{"field": "a", "op": "lte", "value": "123"}]
    const updateSearch = (filterHook, filterProperty, filterText) => {
        setVal(filterText)
        if (filterText == null){
            filterHook.setColumnFilter(filterProperty, null);
            return
        }
        filterText = filterText.trim()
        let searchVal
        if (!filterText || filterText === "*" || filterText === "**") {
            filterHook.setColumnFilter(filterProperty, null);
            return
        }
        let first = filterText.slice(0,1)
        let last = filterText.slice(-1)
        Log.Debug("First: ", first, " Last: ", last)
        if (first === '*' && last === '*') {
            searchVal = "%" + filterText.slice(1, - 1) + "%"
        } else if (first === '*') {
            searchVal = "%" + filterText.slice(1)
        } else  if (last === '*')  {
            searchVal = filterText.slice(0, - 1) + "%"
        } else {
            searchVal = "%" + filterText + "%"
        }
        Log.Debug("Filter Value: ", searchVal)
        let newFilter = {
            "field": filterProperty,
            "op": "ilike",
            "value": searchVal
        }
        filterHook.setColumnFilter(filterProperty, newFilter);
    }


    return <div className="slds-form-element">
        <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
        <input type="search" className="slds-input slds-combobox__input"
                  style={val ? {background : "#e7f3fe"} : {}}
                  id="combobox-id-1"
                  aria-autocomplete="list"
                  aria-controls="listbox-id-1"
                  autoComplete="off"
                  onChange={(event) => {
                      updateSearch(filterHook, filterProperty, event.target.value);
                  }}
                  value={val}  />
            {val ?
            <InputButton iconPosition={"right"} iconName={"clear"} onClick={() => updateSearch(filterHook, filterProperty, "")}/>
            : null}
        </div>
     </div>

}




export function ColumnBooleanFilter(props) {
    //todo search/filter from and use it for update of search
    const t = useT()
    const filterHook = props.filterHook
    const filterProperty = props.property
    const [val, setVal] = useState(null);


    //[{"field": "a", "op": "lte", "value": "123"}]
    const updateSearch = (filterHook, filterProperty, filterValue) => {
        setVal(filterValue)
        if (!filterValue){
            filterHook.setColumnFilter(filterProperty, null);
            return
        }

        let newFilter = {
            "field": filterProperty,
            "op": "eq",
            "value": filterValue
        }
        filterHook.setColumnFilter(filterProperty, newFilter);
    }

    return  <select className="slds-select" id={filterProperty} onChange={(event) =>
        updateSearch(filterHook, filterProperty, event.target.value)}   style={val ? {background : "#e7f3fe"} : {}}>
            <option key={"null"} value={""}></option>
            <option key={"true"} value={true} label={t("common.bool.yes", "yes")}>{t("common.bool.yes", "yes")}</option>
            <option key={"false"} value={false} label={t("common.bool.no", "no")}>{t("common.bool.no", "no")}</option>
        </select>

}
