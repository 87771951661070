import {usePagination} from "../../common/hooks/usePagination";
import {useSearch} from "../../common/hooks/useSearch";
import {useQuery} from "@apollo/react-hooks";
import Page from "../../common/ui/page";
import {Link} from "react-router-dom";
import * as React from "react";
import gql from "graphql-tag";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import CustomDataTableCell from "../../common/ui/data-table/customCell";
import {useSort} from "../../common/hooks/useSort";

const sortVbat = {property: 'properties.device"."vbat.value', direction: "asc"}
const QUERY_HARDWARE_BATTERY_LIST = gql`
    query ($page: PaginationInputType, $search: String $sort: SortInputType) {
        devices(page: $page, sort: $sort, search: $search) {
            id
            addr
            name
            lastReceived
            description
            app {
                id
                name
            }
            organisation {
                id
                name
            }
            deviceType {
                id
                displayName
            }
            propertiesRaw
            initialConfigRaw
            serial
        }
    }`;

const HardwareBatteryTable = (props) => {
    const now = new Date()
    return <GenericDataTable id={"hardware-table"} items={props.result.data && props.result.data.devices || []} tableConfigDefault={{}}
                             gqlResult={props.result}
                             page={props.page} search={props.search}
                             sort={props.sort}>
        <DataTableColumn label="VBat" sortable={true} isSorted={props.sort.sortInfo?.property === "properties.device\".\"vbat.value"} property={'properties.device"."vbat.value'} title={"VBat"}>
            <CustomDataTableCell key="vbat" render={(props) => (
                JSON.parse(props.item.propertiesRaw)?.["device.vbat"]?.value
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Last Seen (Days ago)" sortable={true} property="lastReceived" title={"Last Reveived"}>
            <CustomDataTableCell key="lastReceived" render={(props) => (
                Math.floor((now.getTime() - Date.parse(props.item.lastReceived))/1000/60/60/24)
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Name" property="name" title={"Name"}>
            <CustomDataTableCell key="id" render={(props) => (
                <Link to={`./hardware/${props.item.id}`}>{props.item.name}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Address" property="addr" title={"Addr"}>
            <CustomDataTableCell key="id" render={(props) => (
                <Link to={`./hardware/${props.item.id}`}>{props.item.addr}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Device Type" property={"deviceType"}>
            <CustomDataTableCell key="type" render={(props) => (
                <Link to={`deviceTypes/${props.item.deviceType.id}`}>{props.item.deviceType.displayName}</Link>
            )}/>
        </DataTableColumn>

        <DataTableColumn label="FWVersion" property="Version App" title={"App Version"}>
            <CustomDataTableCell key="FWVersion" render={(props) => (
             JSON.parse(props.item.propertiesRaw)?.["device.app"]?.value
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Version Boot" property="boot Version" title={"Boot Version"}>
            <CustomDataTableCell key="FWVersion" render={(props) => (
             JSON.parse(props.item.propertiesRaw)?.["device.boot"]?.value
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Organization" property={"Organization"}>
            <CustomDataTableCell key="type" render={(props) => (
                props.item.organisation ? <Link to={`/configuration/organisations/${props.item.organisation.id}`}>{props.item.organisation.name}</Link> : null
            )}/>
        </DataTableColumn>
    </GenericDataTable>
};


export const HardwareBatteryPage = () => {
    const page = usePagination();
    const search = useSearch();
    const sort = useSort(sortVbat)

    const result = useQuery(QUERY_HARDWARE_BATTERY_LIST, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput(),
            sort: sort.getGraphqlSortInput(),
        }
    });


    return <Page
        trail={[<Link to="./hardware" key={1}>Hardware List</Link>]}
        title={"Hardware Battery List"}
    >

        <HardwareBatteryTable result={result} page={page} search={search} sort={sort}/>

    </Page>;
};
