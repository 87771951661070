import React, {useState} from "react";
import {Field, Form, Formik} from "formik";
import {FormActions, SldsInputField} from "../common/ui/form/formElements";
import {getQueryStringParams} from "../common/helper";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useAuthContext} from "../common/context/authContext";
import {Button} from "@salesforce/design-system-react";
import PlatformLogo from "../PlatformLogo";
import {FrontendConfigContext} from "../common/context/frontendConfigContext";
import {useT} from "../common/i18n";


const LoginPage = (props) => {
    const t = useT();
    const [loginError, setLoginError] = useState(false);
    const location = useLocation();
    const auth = useAuthContext();
    const history = useHistory();
    const platformName = React.useContext(FrontendConfigContext)?.platformName;
    return <>
        <div className="slds-grid slds-grid--align-center">
            <div className="slds-p-top--large">
                <div className="slds-p-around--medium">
                    <PlatformLogo className="slds-global-header__logo slds-m-bottom--medium" />
                    <p className="slds-text-heading--medium">{t("login.sign-in-to", "Sign in to {{platformName}}" , {platformName: platformName})}</p>
                </div>
                <div className="slds-card slds-p-around--medium">
                    {loginError &&
                    <div style={{color: "rgb(194, 57, 52)"}}>
                        Login failed. please try again.
                    </div>
                    }
                    <Formik
                        onSubmit={(values, actions) => {
                            auth.login(values.login, values.password)
                                .then(json => {
                                    const queryStringParams = getQueryStringParams(location.search);
                                    const redirectTo = queryStringParams.r || "/";
                                    history.push(redirectTo);
                                })
                                .catch(err => {
                                    setLoginError(true);
                                    actions.setSubmitting(false);
                                });
                        }}
                        initialValues={{
                            login: "",
                            password: ""
                        }}
                    >
                        <Form>
                            <SldsInputField label={t("login.username", "Username / E-Mail")} name={"login"}/>
                            <SldsInputField type={"password"} label={t("login.password", "Password")} name={"password"}/>
                            <FormActions>
                                <Field render={(formik) => <Button type="submit" className="slds-button--brand"
                                                                   disabled={formik.form.isSubmitting} {...props}>{t('login.login', "Login")}</Button>}/>
                                <div className="slds-float_right">
                                    <Link to={"/auth/restore-password"} className={"slds-p-bottom--x-small"}>{t("login.forgot-password", "Forgot Password?")}</Link>
                                </div>
                            </FormActions>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    </>;
};

export default LoginPage;