import React from "react";
import PropTypes from "prop-types";
import {Log} from "../../log";

export default function ProgressBar(props) {
    const {min, max, current} = props;
    let percent = 0;
    let range = max - min;
    if (range) {
        percent = current / range;
    }
    if (percent < 0) {
        percent = 0;
    }
    if (percent > 1) {
        percent = 1;
    }
    return <div className="slds-progress-bar" aria-valuemin={min} aria-valuemax={max} aria-valuenow={current} role="progressbar">
  <span className="slds-progress-bar__value" style={{width:`${(percent*100).toFixed(0)}%`}}>
    <span className="slds-assistive-text">Progress: {(percent * 100).toFixed(0)}%</span>
  </span>
    </div>;
}

ProgressBar.defaultProps = {
    min: 0,
    max: 100,
    current: 0,
};

ProgressBar.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    current: PropTypes.number,
};