import {Modal} from "../../common/slds";
import {Formik} from "formik";
import {Log} from "../../common/log";
import {Form} from "../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import * as React from "react";
import {useMutation} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useHistory} from "react-router-dom"

const AddOrganisationDialog = (props) => {
    let {isOpen, onRequestClose} = props;
    const history = useHistory();

    const [createOrganisation] = useMutation(gql`
        mutation($input: OrganisationInput) {
            createOrganisation(input: $input) {
                id
            }
        }
    `, {
        onCompleted: (data) => props.onAdded(data.createOrganisation)
    });

    return <Modal isOpen={isOpen} onRequestClose={onRequestClose} title={"Create Organisation"}>
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                createOrganisation({
                    variables: {
                        input: {
                            name: values.name
                        }
                    }
                }).then((res) => {
                    onRequestClose();
                    history.push(`/configuration/organisations/${res.data.createOrganisation.id}`)
                }, (e) => {
                    actions.setFieldError("global", e);
                    Log.Error("Failed to create organisation", e);
                }).finally(() => {
                    actions.setSubmitting(false);
                })
            }}>
            <Form>
                <SldsInputField name={"name"} label={"Name"} placeholder={"Organisation Name"} required={true}/>
                <FormActions>
                    <SubmitButtonField>Create</SubmitButtonField>
                    <CancelButtonField onClick={onRequestClose}/>
                </FormActions>
            </Form>
        </Formik>
    </Modal>;
};

export default AddOrganisationDialog;